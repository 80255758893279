import { faBars, faSignOutAlt, faUserCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Flags from 'country-flag-icons/react/3x2'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { logout } from '@/apis/user/auth'
import { ReactComponent as LanguageSVG } from '@/assets/images/language-btn.svg'
import { metaMask, metaMaskHooks } from '@/connectors/metamask'
import { THEME } from '@/constants/color'

import { ConnectMetaMask } from './ConnectWallet/ConnectMetaMask'

const { useError, useIsActive, useAccounts } = metaMaskHooks

const MenuBar = () => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const error = useError()
  const isActive = useIsActive()
  const accounts = useAccounts()
  const walletId = React.useMemo(() => accounts?.[0], [accounts])

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const onChangeLang = (lang: string) => {
    i18n.changeLanguage(lang)
    localStorage.setItem('lang', lang)
    handleClose()
  }

  const onNavigate = (to: string) => {
    navigate(to)
    handleClose()
  }

  const handleLogout = async () => {
    if (!walletId) return null
    await metaMask.deactivate()
    await logout(walletId)
  }

  return (
    <Box>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <FontAwesomeIcon icon={faBars} color={THEME.LIGHT_BLUE} size="lg" />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
          style: { padding: '18px 0 0 0' },
        }}
      >
        <MenuItem onClick={() => onNavigate('/user/profile')}>
          <FontAwesomeIcon
            icon={faUserCircle}
            color={THEME.GREY}
            style={{ marginRight: '22px', width: '40px', height: '40px' }}
          />
          <Box>{t('navbar.profile.user-profile-button')}</Box>
        </MenuItem>
        <MenuItem style={{ paddingLeft: '10px' }}>
          <ConnectMetaMask />
        </MenuItem>
        <MenuItem style={{ alignItems: 'flex-start', margin: '0 0 12px 0' }}>
          <LanguageSVG />
          <Box display="flex" flexDirection="column" margin="8px 0 0 24px">
            <Box width="84px" onClick={() => onChangeLang('en')} margin="0 0 24px 0">
              ENG <Flags.US width="24px" style={{ float: 'right', transform: 'translateY(25%)' }} />
            </Box>
            <Box width="84px" onClick={() => onChangeLang('th')}>
              TH <Flags.TH width="24px" style={{ float: 'right', transform: 'translateY(25%)' }} />
            </Box>
            {/* TODO: RELEASED */}
            {/* <Box width="84px" onClick={() => onChangeLang('cn')}>
              CHN <Flags.CN width="24px" style={{ float: 'right', transform: 'translateY(25%)' }} />
            </Box> */}
          </Box>
        </MenuItem>
        {/* TODO: release-2 */}
        {/* <MenuItem>
          <ThemeBtn onClick={openLineShop}>{t('navbar.buy-now')}</ThemeBtn>
        </MenuItem> */}
        <MenuItem onClick={() => onNavigate('/collection/1')} style={{ padding: '15px' }}>
          <Box>{t('navbar.collection')}</Box>
        </MenuItem>
        <MenuItem onClick={() => onNavigate('/collection/1/product')} style={{ padding: '15px' }}>
          <Box>{t('navbar.about-product')}</Box>
        </MenuItem>
        <MenuItem onClick={() => onNavigate('/project')} style={{ padding: '15px' }}>
          <Box>{t('navbar.about-project')}</Box>
        </MenuItem>
        {!error && isActive && (
          <MenuItem style={{ backgroundColor: THEME.RED, padding: '15px' }} onClick={handleLogout}>
            <FontAwesomeIcon
              icon={faSignOutAlt}
              color={THEME.WHITE}
              style={{ marginRight: '8px', width: '24px', height: '24px' }}
            />
            <Box display="flex" alignItems="center" color={THEME.WHITE}>
              {t('navbar.profile.logout-button')}
            </Box>
          </MenuItem>
        )}
        {/* <MenuItem onClick={handleClose}>
          <StyledLink to="/faq">{t('navbar.faq')}</StyledLink>
        </MenuItem> */}
        {/* TODO: RELEASED */}
        {/* <MenuItem onClick={handleClose}>
          <StyledLink to="/collection/1/product">{t('navbar.about-product')}</StyledLink>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <StyledLink to="/project">{t('navbar.about-project')}</StyledLink>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <StyledLink to="/faq">{t('navbar.faq')}</StyledLink>
        </MenuItem> */}
      </Menu>
    </Box>
  )
}

export default React.memo(MenuBar)
