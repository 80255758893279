import { Box } from '@mui/system'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { THEME } from '@/constants/color'
import { Size } from '@/styles'

import ProjectDateBox from './ProjectDateBox'
import TimelineDot from './TimelineDot'

const ProjectDateHorizontal = ({ type }: { type: string }) => {
  const { t } = useTranslation()
  const getTextI18N = () => {
    switch (type) {
      case 'ROADMAP':
        return 'about-project.road-map'

      default:
        return 'home.section4'
    }
  }

  return (
    <Box display="flex" justifyContent="center">
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(4, 1fr)',
          width: 'fit-content',
        }}
      >
        <Box marginTop="72px">
          <ProjectDateBox direction="down">
            <Box {...Size.L}>{t(`${getTextI18N()}.q1.title`)}</Box>
            <Box>{t(`${getTextI18N()}.q1.description`)}</Box>
          </ProjectDateBox>
        </Box>

        <Box></Box>
        <Box>
          <ProjectDateBox direction="down" bgcolor="#F5EDD5" borderColor="#CEB26B">
            <Box {...Size.L}>{t(`${getTextI18N()}.q3.title`)}</Box>
            <Box>{t(`${getTextI18N()}.q3.description`)}</Box>
          </ProjectDateBox>
        </Box>
        <Box></Box>
        <Box
          sx={{
            height: 0,
            width: '100%',
            border: `4px solid ${THEME.LIGHT_BLUE}`,
            borderRadius: '3px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <TimelineDot color={THEME.LIGHT_BLUE} borderColor="#FFFFFF" />
        </Box>

        <Box
          sx={{
            height: 0,
            width: '100%',
            border: `4px solid ${THEME.LIGHT_BLUE}`,
            borderRadius: '3px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <TimelineDot color={THEME.LIGHT_BLUE} borderColor="#FFFFFF" />
        </Box>

        <Box
          sx={{
            ':before,:after': {
              content: '""',
              display: 'block',
              position: 'absolute',
              backgroundColor: 'transparent',
              pointerEvents: 'none',
            },
            ':before': {
              top: 0,
              left: 0,
              right: '50%',
              bottom: '80%',
              borderTop: `8px solid ${THEME.LIGHT_BLUE}`,
              borderLeft: `8px solid ${THEME.LIGHT_BLUE}`,
            },
            ':after': {
              bottom: 0,
              right: 0,
              left: '50%',
              top: '80%',
              borderRight: '8px dashed #DFDFDF',
              borderBottom: '8px dashed #DFDFDF',
            },
            position: 'relative',
            height: 0,
            width: '100%',
            borderRadius: '3px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <TimelineDot color="#CEB26B" borderColor="#FFFFFF" marginTop="8px" marginLeft="8px" hightlight />
        </Box>

        <Box
          sx={{
            ':before,:after': {
              content: '""',
              display: 'block',
              position: 'absolute',
              backgroundColor: 'transparent',
              pointerEvents: 'none',
            },
            ':before': {
              top: 0,
              left: 0,
              right: '50%',
              bottom: '80%',
              borderTop: '8px dashed #DFDFDF',
              borderLeft: '8px dashed #DFDFDF',
            },
            ':after': {
              bottom: 0,
              right: 0,
              left: '50%',
              top: '80%',
              borderRight: '8px dashed #DFDFDF',
              borderBottom: '8px dashed #DFDFDF',
            },
            position: 'relative',
            height: 0,
            width: '100%',
            borderRadius: '3px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <TimelineDot color="#CEB26B" borderColor="#FFFFFF" marginTop="8px" marginLeft="6px" />
        </Box>
        <Box></Box>
        <ProjectDateBox direction="up">
          <Box {...Size.L}>{t(`${getTextI18N()}.q2.title`)}</Box>
          <Box>{t(`${getTextI18N()}.q2.description`)}</Box>
        </ProjectDateBox>
        <Box></Box>
        <ProjectDateBox direction="up" bgcolor="#F5EDD5" borderColor="#CEB26B">
          <Box {...Size.L}>{t(`${getTextI18N()}.q4.title`)}</Box>
          <Box>{t(`${getTextI18N()}.q4.description`)}</Box>
        </ProjectDateBox>
      </Box>
    </Box>
  )
}

export default memo(ProjectDateHorizontal)
