import { faDiscord, faInstagramSquare, faLine, faTiktok, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/system'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { TwitterTimelineEmbed } from 'react-twitter-embed'

import Section3Quote from '@/assets/images/common/quote.png'
import HeaderBg from '@/assets/images/header-bg.svg'
import Carousel1 from '@/assets/images/home/carousel_1.png'
import Carousel2 from '@/assets/images/home/carousel_2.png'
import Carousel3 from '@/assets/images/home/carousel_3.png'
import Section1Banner from '@/assets/images/home/section1_banner.png'
import Content1Bg from '@/assets/images/home/section1_bg.png'
import Section2Banner from '@/assets/images/home/section2_banner.png'
import Section3Banner from '@/assets/images/home/section3_banner.jpg'
import Carousel from '@/components/Carousel'
import ProjectDate from '@/components/ProjectDate/ProjectDate'
import { THEME } from '@/constants/color'
import { SOCIAL_URL } from '@/constants/url'
import { useResponsive } from '@/hooks/useResponsive'
import { CenterContainer, SectionContainer, Size } from '@/styles'
import { onClickUrl } from '@/utils'

import CarouselLink from './components/CarouselLink'
import Section1Content from './components/Section1Content'
import { CarouselContainer, ContentWithBg, HeaderContentWithBg } from './styles/index'

const HomePage = () => {
  const { t } = useTranslation()
  const socialWidth = useResponsive({ desktop: '560', tablet: '400', mobile: '360' })
  const socialHeight = useResponsive({ desktop: '640', tablet: '640', mobile: '500' })

  return (
    <>
      <HeaderContentWithBg
        image={HeaderBg}
        minHeight={useResponsive({ desktop: '550px', smallScreen: '800px', tablet: '1000px', mobile: '900px' })}
      >
        <SectionContainer>
          <Box flex={1}>
            <Box
              {...useResponsive({ desktop: Size['100L'], smallScreen: Size.XXXL, tablet: Size.XXL })}
              marginBottom="40px"
              color="white"
            >
              {t('home.header.title')}
            </Box>
            <Box
              {...useResponsive({ desktop: Size.XXL, smallScreen: Size.XL, tablet: Size.L })}
              marginBottom="40px"
              color="white"
            >
              {t('home.header.sub-title')}
            </Box>
            <Box {...useResponsive({ desktop: Size.L, smallScreen: Size.M })} marginBottom="40px" color="white">
              {t('home.header.description')}
            </Box>
          </Box>
          <CarouselContainer>
            {/* TODO: Change link to /collection/1/boxes */}
            <Carousel
              slides={[
                {
                  key: 0,
                  content: <CarouselLink src={Carousel1} to="/collection/1/product" />,
                },
                {
                  key: 1,
                  content: <CarouselLink src={Carousel2} to="/collection/1/product" />,
                },
                {
                  key: 2,
                  content: <CarouselLink src={Carousel3} to="/collection/1/product" />,
                },
              ]}
              autoPlay
            />
          </CarouselContainer>
        </SectionContainer>
      </HeaderContentWithBg>

      <ContentWithBg
        image={useResponsive({ desktop: Content1Bg, tablet: '#' })}
        minHeight={useResponsive({ desktop: '600px', tablet: '800px', mobile: '842px' })}
      >
        <SectionContainer>
          <Box margin={useResponsive({ desktop: '32px 24px 24px', tablet: '64px 0 0' })}>
            <Box {...useResponsive({ desktop: Size.XL, tablet: Size.L })}>{t('home.section1.title')}</Box>
            <Box display="flex" margin="60px 0 24px 0" flexDirection="column">
              <Box display="flex" flexDirection={useResponsive({ tablet: 'column', desktop: 'row' })}>
                <Section1Content index="1" />
                <Section1Content index="2" />
              </Box>
              <Box display="flex" marginTop="21px" flexDirection={useResponsive({ tablet: 'column', desktop: 'row' })}>
                <Section1Content index="3" />
                <Section1Content index="4" />
              </Box>
            </Box>
          </Box>
          <Box textAlign={useResponsive({ smallScreen: 'center', desktop: 'right' })}>
            <img
              src={Section1Banner}
              alt="content1"
              style={{
                width: useResponsive({ smallScreen: '100%', desktop: '760px' }),
                height: useResponsive({ smallScreen: '100%', desktop: '337px' }),
                margin: useResponsive({ desktop: '0 0 32px', tablet: '0 0 64px' }),
              }}
            />
          </Box>
        </SectionContainer>
      </ContentWithBg>

      <ContentWithBg image={Section2Banner} minHeight={useResponsive({ desktop: '550px', tablet: '293px' })}>
        <CenterContainer>
          <Box {...useResponsive({ desktop: Size.XXL, tablet: Size.XL })} color="white" textAlign="center">
            {t('home.section2.title')}
          </Box>
          <Box {...useResponsive({ desktop: Size.L, tablet: Size.M })} color="white" marginTop="32px">
            {t('home.section2.description')}
          </Box>
        </CenterContainer>
      </ContentWithBg>

      <CenterContainer
        display={useResponsive({ desktop: 'flex', smallScreen: 'block' })}
        margin={useResponsive({ desktop: '64px 128px', smallScreen: '64px', tablet: '32px', mobile: '18px' })}
      >
        <SectionContainer>
          <Box textAlign="center">
            <img
              src={Section3Banner}
              alt="section3"
              width={useResponsive({ desktop: '536px', tablet: '357px', mobile: '270px' })}
              height={useResponsive({ desktop: '337px', tablet: '224px', mobile: '167px' })}
            />
          </Box>
          <Box margin={useResponsive({ desktop: '0 0 0 64px', smallScreen: '32px 24px' })}>
            <img src={Section3Quote} alt="section quote" width="64px" height="64px" />
            <Box {...useResponsive({ desktop: Size.L, tablet: Size.M })} margin={`24px 0 40px`}>
              {t('home.section3.title')}
            </Box>
            <Box {...useResponsive({ desktop: Size.M, tablet: Size.S })} color={THEME.DARK_GREY_2}>
              {t('home.section3.description')}
            </Box>
          </Box>
        </SectionContainer>
      </CenterContainer>
      <ProjectDate />
      {/* TODO: release-2 */}
      {/* <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        minHeight={useResponsive({ desktop: '220px', tablet: '292px' })}
        bgcolor={THEME.LIGHT_GREY}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent={useResponsive({ desktop: 'space-between', tablet: 'center' })}
          flexDirection={useResponsive({ desktop: 'row', tablet: 'column' })}
          maxWidth="1440px"
          width="100%"
          padding={useResponsive({ desktop: '0 120px 0', tablet: '0' })}
        >
          <Box
            {...useResponsive({ desktop: Size.XL, tablet: Size.L })}
            margin={useResponsive({ desktop: '0', tablet: '32px' })}
          >
            {t('home.section5.title')}
          </Box>
          <ThemeBtn onClick={openLineShop}>{t('home.section5.button.title')}</ThemeBtn>
        </Box>
      </Box> */}

      <Box minHeight={useResponsive({ desktop: '1060px', tablet: '1440px' })}>
        <CenterContainer padding={useResponsive({ desktop: '64px', tablet: '48px' })} maxWidth="1440px" margin="auto">
          <Box {...useResponsive({ desktop: Size.XXL, tablet: Size.XL, mobile: Size.L })}>
            {t('home.section6.title')}
          </Box>
          <Box display="flex" flexWrap="wrap" justifyContent="center">
            <Box
              padding={useResponsive({ desktop: '32px', tablet: '16px', mobile: '8px' })}
              onClick={() => onClickUrl(SOCIAL_URL.TWITTER)}
            >
              <FontAwesomeIcon
                icon={faTwitter}
                size={useResponsive({ desktop: '3x', tablet: '2x', mobile: '1x' })}
                style={{ cursor: 'pointer' }}
              />
            </Box>
            <Box
              padding={useResponsive({ desktop: '32px', tablet: '16px', mobile: '8px' })}
              onClick={() => onClickUrl(SOCIAL_URL.INSTAGRAM)}
            >
              <FontAwesomeIcon
                icon={faInstagramSquare}
                size={useResponsive({ desktop: '3x', tablet: '2x', mobile: '1x' })}
                style={{ cursor: 'pointer' }}
              />
            </Box>
            <Box
              padding={useResponsive({ desktop: '32px', tablet: '16px', mobile: '8px' })}
              onClick={() => onClickUrl(SOCIAL_URL.DISCORD)}
            >
              <FontAwesomeIcon
                icon={faDiscord}
                size={useResponsive({ desktop: '3x', tablet: '2x', mobile: '1x' })}
                style={{ cursor: 'pointer' }}
              />
            </Box>
            <Box
              padding={useResponsive({ desktop: '32px', tablet: '16px', mobile: '8px' })}
              onClick={() => onClickUrl(SOCIAL_URL.TIKTOK)}
            >
              <FontAwesomeIcon
                icon={faTiktok}
                size={useResponsive({ desktop: '3x', tablet: '2x', mobile: '1x' })}
                style={{ cursor: 'pointer' }}
              />
            </Box>
            <Box
              padding={useResponsive({ desktop: '32px', tablet: '16px', mobile: '8px' })}
              onClick={() => onClickUrl(SOCIAL_URL.LINE)}
            >
              <FontAwesomeIcon
                icon={faLine}
                size={useResponsive({ desktop: '3x', tablet: '2x', mobile: '1x' })}
                style={{ cursor: 'pointer' }}
              />
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection={useResponsive({ tablet: 'column', desktop: 'row' })}
            justifyContent="space-evenly"
            alignItems="center"
            width="100%"
            marginTop={useResponsive({ desktop: '50px', tablet: '32px', mobile: '18px' })}
            overflow="hidden"
          >
            <TwitterTimelineEmbed
              sourceType="profile"
              screenName="DevalokaNfts"
              options={{
                height: parseInt(socialHeight, 10),
                width: parseInt(socialWidth, 10),
              }}
            />

            <iframe
              src={`https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fdevalokanfts%2F&tabs=timeline&width=${socialWidth}&height=${socialHeight}&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=189517364941203`}
              height={socialHeight}
              width={socialWidth}
              style={{
                border: 'none',
                overflow: 'hidden',
                margin: useResponsive({ desktop: '0 0 0 32px', tablet: '64px 0' }),
              }}
              allowFullScreen={true}
              title="facebook"
              allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
            ></iframe>
          </Box>
        </CenterContainer>
      </Box>
    </>
  )
}

export default React.memo(HomePage)
