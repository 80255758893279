import { Box, BoxProps } from '@mui/system'
import * as React from 'react'
import styled from 'styled-components'

import { useResponsive } from '@/hooks/useResponsive'

type TextProps = {
  size: any
  color?: any
}

export const Size = {
  XXS: { fontSize: '12px', lineHeight: '20px', fontWeight: '400' },
  XS: { fontSize: '14px', lineHeight: '22px', fontWeight: '400' },
  S: { fontSize: '16px', lineHeight: '24px', fontWeight: '400' },
  M: { fontSize: '18px', lineHeight: '26px', fontWeight: '400' },
  L: { fontSize: '24px', lineHeight: '32px', fontWeight: '600' },
  XL: { fontSize: '32px', lineHeight: '40px', fontWeight: '600' },
  XXL: { fontSize: '40px', lineHeight: '48px', fontWeight: '600' },
  XXXL: { fontSize: '48px', lineHeight: '56px', fontWeight: '600' },
  '100L': { fontSize: '100px', lineHeight: '108px', fontWeight: '600' },
}

export const Text = styled(Box)`
  font-size: ${({ size }: TextProps) => (size ? size : 14) + 'px;'};
  color: ${({ color }: TextProps) => (color ? color : 'black')};
  padding: 10px;
`

export const Subtitle = React.memo((props: BoxProps) => (
  <Box {...useResponsive({ desktop: Size.XL, tablet: Size.L })} {...props}>
    {props.children}
  </Box>
))

export const Title = React.memo((props: BoxProps) => {
  return (
    <Box {...useResponsive({ desktop: Size.XXL, tablet: Size.XL })} {...props}>
      {props.children}
    </Box>
  )
})

export const HeaderContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
`

export const SectionContainer = styled(HeaderContainer)`
  max-width: 1440px;

  @media (max-width: 1439px) {
    flex-direction: column;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export const CenterContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
`
