import { Select } from '@mui/material'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { THEME } from '@/constants/color'
import { Size } from '@/styles'

export const Container = styled.div`
  position: fixed;
  background: white;
  height: 80px;
  width: 100vw;
  display: flex;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  align-items: center;
  z-index: 10;
`

export const LogoContainer = styled.div`
  margin: 0 40px 0 60px;
  flex: 1;

  @media (max-width: 768px) {
    margin: 0 20px 0 40px;
  }

  @media (max-width: 390px) {
    margin: 0 20px 0 20px;
  }
`

export const LogoImg = styled.img`
  height: 60px;
`

export const StyledSelect = styled(Select)`
  font-weight: bold !important;

  & > fieldset {
    border: none;
  }
`

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${({ color }: any) => (color ? color : THEME.BLUE)};
  font-size: ${Size.M.fontSize};
  line-height: ${Size.M.lineHeight};
  font-weight: 600;
`

export const ConnectToWalletButton = styled.button`
  font-size: ${Size.M.fontSize};
  line-height: ${Size.M.lineHeight};
  background: #eaeaea;
  border: 1px solid ${THEME.LIGHT_BLUE};
  border-radius: 2px;
  padding: 10px 39px;
  cursor: pointer;
`
