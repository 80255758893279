import { Box, BoxProps } from '@mui/system'
import * as React from 'react'

type TimelineDotProps = BoxProps & {
  color: string
  borderColor: string
  hightlight?: boolean
  rightLine?: boolean
}

const TimelineDot = ({ color, borderColor, hightlight, rightLine, ...rest }: TimelineDotProps) => {
  const dot = React.useMemo(
    () => (
      <Box
        sx={{
          width: '24px',
          height: '24px',
          transform: 'rotate(45deg)',
          bgcolor: color,
          border: `4px solid ${borderColor}`,
          boxShadow: hightlight ? '0px 0px 20px 5px #D6B757' : '0px 4px 4px rgba(0, 0, 0, 0.25)',
          zIndex: '9',
        }}
        {...rest}
      />
    ),
    [borderColor, color, hightlight, rest],
  )

  return rightLine ? (
    <Box>
      <Box display="flex">
        {dot}
        <Box width="48px" borderTop={`2px dashed ${color}`} marginY="auto" />
      </Box>
    </Box>
  ) : (
    dot
  )
}

export default React.memo(TimelineDot)
