import { Tab } from '@mui/material'
import styled from 'styled-components'

import { Size } from '@/styles'

export const CollectionGalleryTab = styled(Tab)`
  font-size: ${Size.S.fontSize} !important;
  line-height: ${Size.S.lineHeight} !important;
  text-transform: none !important;
  font-family: 'El Messiri', sans-serif !important;
`
