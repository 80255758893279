import { faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'
import { useState } from 'react'
import styled from 'styled-components'

import { THEME } from '@/constants/color'

type Prop = { visible: boolean }

const StyledButton = styled(Box)`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  left: 92%;
  bottom: 10%;
  font-size: 3rem;
  z-index: 9999;
  cursor: pointer;
  background-color: ${THEME.GOLD};
  visibility: ${({ visible }: Prop) => (visible ? 'visible' : 'hidden')};
  opacity: ${({ visible }: Prop) => (visible ? 1 : 0)};
  transition: all 0.3s;

  @media (max-width: 768px) {
    left: 85%;
  }

  @media (max-width: 390px) {
    left: 70%;
  }
`

export const GoToTopButton = () => {
  const [visible, setVisible] = useState(false)

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop
    if (scrolled > 20) {
      setVisible(true)
    } else if (scrolled <= 20) {
      setVisible(false)
    }
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  window.addEventListener('scroll', toggleVisible)

  return (
    <StyledButton onClick={scrollToTop} visible={visible}>
      <FontAwesomeIcon icon={faChevronUp} color={THEME.BLUE} size="xs" />
    </StyledButton>
  )
}
