import PeopleIcon from '@mui/icons-material/People'
import StarsIcon from '@mui/icons-material/Stars'
import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import { Box } from '@mui/system'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import AboutUsBanner from '@/assets/images/about-project/about_us_banner.png'
import Section1Banner from '@/assets/images/about-project/section1_banner.png'
import Section2Banner from '@/assets/images/about-project/section2_banner.png'
import CenterBanner from '@/components/CenterBanner'
import RoadMap from '@/components/ProjectDate/RoadMap'
import { ParseT } from '@/components/common/ParseT'
import { WContainer } from '@/components/common/WContainer'
import { THEME } from '@/constants/color'
import { LANG } from '@/constants/language'
import { useResponsive } from '@/hooks/useResponsive'
import { Size, Subtitle } from '@/styles'

import IconTitle from './components/IconTitle'

const ProjectPage = () => {
  const { t, i18n } = useTranslation()
  const textIndent = i18n.language === LANG.TH ? '2em' : '0'

  const [searchParams, setSearchParams] = useSearchParams()

  const storyRef = React.useRef<HTMLElement>(null)
  const aboutUsRef = React.useRef<HTMLElement>(null)
  const painPointRef = React.useRef<HTMLElement>(null)

  React.useEffect(() => {
    setTimeout(() => {
      const q = searchParams.get('q')
      const offset = 72

      switch (q) {
        case 'story':
          window.scroll({ top: (storyRef?.current?.offsetTop ?? 0) + offset, behavior: 'smooth' })
          break
        case 'painpoint':
          window.scroll({ top: (painPointRef?.current?.offsetTop ?? 0) - offset, behavior: 'smooth' })
          break
        case 'aboutus':
          window.scroll({ top: (aboutUsRef?.current?.offsetTop ?? 0) - offset, behavior: 'smooth' })
          break
      }

      setSearchParams({})
    }, 100)
  }, [searchParams, setSearchParams])

  return (
    <Box color={THEME.BLUE}>
      <Box
        padding={useResponsive({ desktop: '0 60px', tablet: '0 24px' })}
        style={{
          background: 'linear-gradient(180deg, #DAE0F4 0%, rgba(218, 224, 244, 0) 100%)',
        }}
      >
        <WContainer>
          <Box
            {...Size.XXL}
            textAlign="center"
            padding={useResponsive({ desktop: '80px 0 100px 0', tablet: '80px 0 64px' })}
            ref={storyRef}
          >
            {t('about-project.title')}
          </Box>
          <Subtitle marginBottom="40px">{t('about-project.header.title')}</Subtitle>
          <Box marginBottom="16px" style={{ textIndent }}>
            {ParseT('about-project.header.description1')}
          </Box>
          <Box marginBottom="16px" style={{ textIndent }}>
            {ParseT('about-project.header.description2')}
          </Box>
          <Box marginBottom="64px" style={{ textIndent }}>
            {ParseT('about-project.header.description3')}
          </Box>
        </WContainer>
      </Box>
      <CenterBanner src={Section2Banner} />
      <Box padding={useResponsive({ desktop: '80px 60px', tablet: '80px 24px' })} ref={painPointRef}>
        <WContainer>
          <Box textAlign="center" {...Size.XL} marginBottom="80px">
            {t('about-project.pain-point.header')}
          </Box>
          <Box
            display="flex"
            flexDirection={useResponsive({ desktop: 'row', custom: { maxWidth: '1100px', value: 'column' } })}
          >
            <Box
              margin={useResponsive({ desktop: '0 auto 0 0', custom: { maxWidth: '1100px', value: '0 0 48px' } })}
              textAlign="center"
            >
              <img
                src={Section1Banner}
                alt="market"
                style={{
                  width: useResponsive({ desktop: '536px', tablet: '100%' }),
                  marginRight: useResponsive({ desktop: '64px', custom: { maxWidth: '1100px', value: '0' } }),
                }}
              />
            </Box>
            <Box
              margin={useResponsive({ desktop: '0 0 0 auto', custom: { maxWidth: '1100px', value: '0' } })}
              maxWidth={useResponsive({ desktop: '700px', custom: { maxWidth: '1100px', value: 'undefined' } })}
            >
              <Box {...Size.L} marginBottom="40px">
                {t('about-project.pain-point.title')}
              </Box>
              <Box marginBottom="16px" style={{ textIndent }}>
                {ParseT('about-project.pain-point.description1')}
              </Box>
              <Box marginBottom="16px" style={{ textIndent }}>
                {ParseT('about-project.pain-point.description2')}
              </Box>
              <Box style={{ textIndent }}>{ParseT('about-project.pain-point.description3')}</Box>
            </Box>
          </Box>
        </WContainer>
      </Box>
      <Box
        style={{
          background: 'linear-gradient(180deg, #EAEAEA 0%, rgba(234, 234, 234, 0) 100%)',
        }}
        ref={aboutUsRef}
      >
        <WContainer
          padding={useResponsive({ desktop: '80px 60px', custom: { maxWidth: '1100px', value: '80px 24px' } })}
        >
          <Subtitle textAlign="center" marginBottom="40px">
            {t('about-project.about-us.header')}
          </Subtitle>
          <Box
            display="flex"
            flexDirection={useResponsive({ desktop: 'row', custom: { maxWidth: '1100px', value: 'column' } })}
          >
            <Box margin={useResponsive({ desktop: '0 48px', custom: { maxWidth: '1100px', value: '0' } })}>
              <Box display="flex" alignItems="center" marginBottom="24px">
                <IconTitle Icon={<StarsIcon sx={{ fontSize: 40 }} style={{ margin: '10px' }} />} />
                <Box {...Size.L} marginLeft="24px">
                  {t('about-project.about-us.title1')}
                </Box>
              </Box>
              <Box marginBottom="64px">{ParseT('about-project.about-us.description1')}</Box>
              <Box display="flex" alignItems="center" marginBottom="24px">
                <IconTitle Icon={<PeopleIcon sx={{ fontSize: 40 }} style={{ margin: '10px' }} />} />
                <Box {...Size.L} marginLeft="24px">
                  {t('about-project.about-us.title2')}
                </Box>
              </Box>
              <Box marginBottom="64px">{ParseT('about-project.about-us.description2')}</Box>
              <Box display="flex" alignItems="center" marginBottom="24px">
                <IconTitle Icon={<ThumbUpIcon sx={{ fontSize: 40 }} style={{ margin: '10px' }} />} />
                <Box {...Size.L} marginLeft="24px">
                  {t('about-project.about-us.title3')}
                </Box>
              </Box>
              <Box marginBottom="64px">{ParseT('about-project.about-us.description3')}</Box>
            </Box>
            <img
              src={AboutUsBanner}
              alt="team"
              style={{ width: useResponsive({ desktop: '50%', tablet: '100%' }), height: '100%', margin: '0 auto' }}
            />
          </Box>
        </WContainer>
      </Box>
      <Box>
        <WContainer padding={useResponsive({ desktop: '80px 60px', tablet: '64px 24px' })}>
          <Subtitle textAlign="center" marginBottom="64px">
            {t('about-project.road-map.title')}
          </Subtitle>
          <RoadMap />
        </WContainer>
      </Box>
    </Box>
  )
}

export default React.memo(ProjectPage)
