import { faUserCircle } from '@fortawesome/free-regular-svg-icons'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Button, Menu, MenuItem } from '@mui/material'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { logout } from '@/apis/user/auth'
import { ReactComponent as UserProfileSVG } from '@/assets/images/profile-btn.svg'
import { metaMask, metaMaskHooks } from '@/connectors/metamask'
import { THEME } from '@/constants/color'
import { Size } from '@/styles'

import { StyledLink } from './styled'

const { useError, useIsActive, useAccounts } = metaMaskHooks

const ProfileMenu = () => {
  const { t } = useTranslation()
  const error = useError()
  const isActive = useIsActive()
  const accounts = useAccounts()
  const walletId = useMemo(() => accounts?.[0], [accounts])

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const isOpen = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = async () => {
    if (!walletId) return null
    await metaMask.deactivate()
    await logout(walletId)
  }

  return (
    <Box>
      <Button
        id="profile-button"
        aria-controls={isOpen ? 'profile-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isOpen ? 'true' : undefined}
        onClick={handleClick}
      >
        <UserProfileSVG />
      </Button>
      <Menu
        id="profile-menu"
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClose}
        MenuListProps={{
          style: { padding: 0, overflow: 'hidden' },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <StyledLink to="/user/profile">
          <MenuItem style={{ width: '185px', height: '60px' }}>
            <Box display="flex" alignItems="center" {...Size.S} style={{ padding: '20px 0' }}>
              <FontAwesomeIcon
                icon={faUserCircle}
                color={THEME.GREY}
                style={{ marginRight: '8px', width: '24px', height: '24px' }}
              />
              {t('navbar.profile.user-profile-button')}
            </Box>
          </MenuItem>
        </StyledLink>
        {!error && isActive && (
          <Box display="flex" alignItems="center" {...Size.S} color={THEME.WHITE} onClick={handleLogout}>
            <MenuItem style={{ backgroundColor: THEME.RED, width: '185px', height: '60px' }}>
              <FontAwesomeIcon
                icon={faSignOutAlt}
                color={THEME.WHITE}
                style={{ marginRight: '8px', width: '24px', height: '24px' }}
              />
              {t('navbar.profile.logout-button')}
            </MenuItem>
          </Box>
        )}
      </Menu>
    </Box>
  )
}

export default ProfileMenu
