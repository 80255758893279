import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import CN from './locales/cn.json'
import EN from './locales/en.json'
import TH from './locales/th.json'

const resources = {
  en: {
    translation: EN,
  },
  th: {
    translation: TH,
  },
  cn: {
    translation: CN,
  },
}

i18n.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem('lang') || 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
})

export default i18n
