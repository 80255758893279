import { Box } from '@mui/material'
import * as React from 'react'

import ThemeCheckbox from '@/components/common/ThemeCheckbox'
import { THEME } from '@/constants/color'
import { useResponsive } from '@/hooks/useResponsive'
import { Size } from '@/styles'

import { CardDetail } from './styled'

type Props = {
  image: any
  title: string
  checkbox?: boolean
  checked?: boolean
  claimed?: boolean
  onCheck?: (id: number, checked: boolean) => void
  id?: number
  tiny?: boolean
}

const ProductCard = ({ image, title, checkbox, checked, claimed, onCheck, id }: Props) => {
  const borderColor = React.useMemo(() => {
    if (checkbox && checked) return THEME.LIGHT_BLUE
    return claimed ? '#D6B757' : 'white'
  }, [checkbox, checked, claimed])

  const onCheckboxChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (onCheck && id !== undefined) {
        onCheck(id, e.target.checked)
      }
    },
    [id, onCheck],
  )

  return (
    <Box
      maxWidth={useResponsive({ desktop: '312px', tablet: '348px', mobile: '174px' })}
      boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
      borderRadius="20px"
      margin="auto"
    >
      <Box
        border={`solid ${borderColor} 10px`}
        borderRadius="20px 20px 0 0"
        position={checkbox ? 'relative' : 'unset'}
        bgcolor={THEME.WHITE}
      >
        {checkbox && <ThemeCheckbox style={{ position: 'absolute' }} onChange={onCheckboxChange} checked={checked} />}
        <img src={image} alt="content" style={{ width: '100%', height: '100%', borderRadius: '24px' }} />
      </Box>
      <CardDetail
        {...Size.L}
        borderRadius="0 0 20px 20px"
        padding={useResponsive({ desktop: '24px', tablet: '15px', mobile: '8px' })}
      >
        #{title}
      </CardDetail>
    </Box>
  )
}

export default React.memo(ProductCard)
