import { LINE_SHOP_LINK, PRODUCTION } from '@/constants'

export const onClickUrl = (_url: string) => {
  window.open(_url, 'blank')
}

export const truncateString = (fullStr: string, strLen: number = 20, separator: string = '...') => {
  if (!fullStr || fullStr.length <= strLen) return fullStr

  separator = separator || '...'

  const sepLen = separator.length
  const charsToShow = strLen - sepLen
  const frontChars = Math.ceil(charsToShow / 2)
  const backChars = Math.floor(charsToShow / 2)

  return fullStr.substring(0, frontChars) + separator + fullStr.substring(fullStr.length - backChars)
}

export const getChainId = () => (process.env.REACT_APP_ENV === PRODUCTION ? 56 : 97)

export const openLineShop = () => window.open(LINE_SHOP_LINK, '_blank')
