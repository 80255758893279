import { Box } from '@mui/system'
import styled from 'styled-components'

type ContentWithBgProps = {
  image?: any
  minHeight?: any
  height?: any
  maxHeight?: any
}

type HeaderContentProps = {
  flex?: number
}

export const ContentWithBg = styled(Box)`
  background-image: ${({ image }: ContentWithBgProps) => (image ? `url('${image}')` : '')};
  background-size: cover;
  background-position: center 50%;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 60px;

  @media (max-width: 768px) {
    padding: 0 24px;
  }

  @media (max-width: 390px) {
    padding: 0 16px;
  }
`

export const HeaderContentWithBg = styled(ContentWithBg)`
  align-items: baseline;
  padding: 120px 60px;

  @media (max-width: 768px) {
    padding: 48px 24px;
  }

  @media (max-width: 390px) {
    padding: 48px 16px;
  }
`

export const ContentContainer = styled(Box)`
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const CarouselContainer = styled(Box)`
  flex: 1;
  height: 500px;
  width: 100%;

  @media (max-width: 1439px) {
    flex: unset;
  }

  @media (max-width: 768px) {
    margin-top: 48px;
    height: 390px;
  }

  @media (max-width: 390px) {
    height: 223px;
  }
`

export const HeaderContent = styled(Box)`
  flex: ${({ flex }: HeaderContentProps) => (flex ? flex : 1)};
`
