import axios from 'axios'
import { ReactElement, useEffect } from 'react'

import configs from '@/configs'
import { metaMask } from '@/connectors/metamask'

const axiosInstance = axios.create({
  baseURL: configs.SERVER_URL,
  withCredentials: true,
})

const AxiosInterceptor = ({ children }: { children: ReactElement }) => {
  useEffect(() => {
    const resInterceptor = (response: any) => {
      return response
    }

    const errInterceptor = async (error: any) => {
      if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        await metaMask.deactivate()
      }

      return Promise.reject(error)
    }

    const interceptor = axiosInstance.interceptors.response.use(resInterceptor, errInterceptor)

    return () => axiosInstance.interceptors.response.eject(interceptor)
  }, [])

  return children
}

export default axiosInstance
export { AxiosInterceptor }
