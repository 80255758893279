import { Box } from '@mui/system'
import styled from 'styled-components'

interface SpaceBoxType {
  spaceRight?: string
  spaceBottom?: string
}

export const SpacedBox = styled(Box)<SpaceBoxType>`
  > *:not(:last-child) {
    margin-right: ${({ spaceRight }: any) => (spaceRight ? spaceRight : '0')};
    margin-bottom: ${({ spaceBottom }: any) => (spaceBottom ? spaceBottom : '0')};
  }
`
