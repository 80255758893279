export const THEME = {
  WHITE: '#FFFFFF',

  BLACK: '#000000',

  BLUE: '#121C3E',
  LIGHT_BLUE: '#1D2D63',

  RED: '#931B10',
  DARK_RED: '#5C110A',

  GOLD: '#D6B757',

  GREY: '#C3CBCD',
  LIGHT_GREY: '#EAEAEA',
  DARK_GREY: '#6F6F6F',
  DARK_GREY_2: '#737B7D',

  DISABLED: '#BFBFBF',
}
