import { Box } from '@mui/material'
import styled from 'styled-components'

export const UtilityPageContainer = styled(Box)`
  background: white;
  border-radius: 5px;
  text-align: center;
  max-height: 64vh;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
`
