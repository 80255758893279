import { Box } from '@mui/system'
import React from 'react'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { THEME } from '@/constants/color'
import { useResponsive } from '@/hooks/useResponsive'
import { Size } from '@/styles'

import ProjectDateBox from './ProjectDateBox'
import TimelineDot from './TimelineDot'

export const ProjectDateVertical = ({ type }: { type: string }) => {
  const { t } = useTranslation()
  const getTextI18N = () => {
    switch (type) {
      case 'ROADMAP':
        return 'about-project.road-map'

      default:
        return 'home.section4'
    }
  }

  const solidTimeline = React.useMemo(
    () => (
      <>
        <Box borderLeft={`4px solid ${THEME.LIGHT_BLUE}`} position="absolute" left="14px" height="100%" />
        <TimelineDot color={THEME.LIGHT_BLUE} borderColor="#FFFFFF" rightLine />
      </>
    ),
    [],
  )

  const dashedLine = React.useMemo(
    () => <Box borderLeft="4px dashed #DFDFDF" position="absolute" left="14px" height="100%" />,
    [],
  )

  return (
    <Box paddingX={useResponsive({ desktop: '64px', tablet: '24px' })}>
      <Box borderLeft={`4px solid ${THEME.LIGHT_BLUE}`} marginLeft="14px" height="60px" />
      <Box display="flex" paddingBottom={useResponsive({ desktop: '72px', tablet: '24px' })} position="relative">
        {solidTimeline}
        <ProjectDateBox>
          <Box {...Size.L} marginBottom="24px">
            {t(`${getTextI18N()}.q1.title`)}
          </Box>
          <Box>{t(`${getTextI18N()}.q1.description`)}</Box>
        </ProjectDateBox>
      </Box>
      <Box display="flex" paddingBottom={useResponsive({ desktop: '72px', tablet: '24px' })} position="relative">
        {solidTimeline}
        <ProjectDateBox>
          <Box {...Size.L} marginBottom="24px">
            {t(`${getTextI18N()}.q2.title`)}
          </Box>
          <Box>{t(`${getTextI18N()}.q2.description`)}</Box>
        </ProjectDateBox>
      </Box>
      <Box display="flex" paddingBottom={useResponsive({ desktop: '72px', tablet: '24px' })} position="relative">
        {dashedLine}
        <TimelineDot color={THEME.GOLD} borderColor="#FFFFFF" rightLine hightlight />
        <ProjectDateBox bgcolor="#F5EDD5" borderColor={THEME.GOLD}>
          <Box {...Size.L} marginBottom="24px">
            {t(`${getTextI18N()}.q3.title`)}
          </Box>
          <Box>{t(`${getTextI18N()}.q3.description`)}</Box>
        </ProjectDateBox>
      </Box>
      <Box display="flex" marginBottom="80px" position="relative">
        {dashedLine}
        <TimelineDot color={THEME.GOLD} borderColor="#FFFFFF" rightLine />
        <ProjectDateBox bgcolor="#F5EDD5" borderColor={THEME.GOLD}>
          <Box {...Size.L} marginBottom="24px">
            {t(`${getTextI18N()}.q4.title`)}
          </Box>
          <Box>{t(`${getTextI18N()}.q4.description`)}</Box>
        </ProjectDateBox>
      </Box>
    </Box>
  )
}

export default memo(ProjectDateVertical)
