import { Box } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

import Logo from '@/assets/images/common/logo.png'
import { SpacedBox } from '@/components/common/SpaceBox'
import ThemeBtn from '@/components/common/ThemeBtn'
import { THEME } from '@/constants/color'
import { useCustomQuery } from '@/hooks/useResponsive'
import { openLineShop } from '@/utils'

import { ConnectMetaMask } from './ConnectWallet/ConnectMetaMask'
import LanguageMenu from './LanguageMenu'
import MenuBar from './MenuBar'
import ProfileMenu from './ProfileMenu'
import { Container, LogoContainer, LogoImg, StyledLink } from './styled'

const NavigationBar = () => {
  const { t } = useTranslation()
  const isMenuBarSize = useCustomQuery('1200px')

  return (
    <Container>
      <LogoContainer>
        <StyledLink to="/">
          <LogoImg src={Logo} alt="Devaloka" />
        </StyledLink>
      </LogoContainer>
      {isMenuBarSize ? (
        <MenuBar />
      ) : (
        <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
          <SpacedBox spaceRight="32px" display="flex" alignItems="left">
            <StyledLink to="/collection/1" color={THEME.DARK_GREY}>
              {t('navbar.collection')}
            </StyledLink>
            <StyledLink to="/collection/1/product" color={THEME.DARK_GREY}>
              {t('navbar.about-product')}
            </StyledLink>
            <StyledLink to="/project" color={THEME.DARK_GREY}>
              {t('navbar.about-project')}
            </StyledLink>
            {/* <StyledLink to="/faq" color={THEME.DARK_GREY}>
              {t('navbar.faq')}
            </StyledLink> */}
          </SpacedBox>
          <Box display="flex" marginRight="60px">
            <SpacedBox marginRight="48px">
              <ThemeBtn padding="11px 24px" onClick={openLineShop}>
                {t('navbar.buy-now')}
              </ThemeBtn>
            </SpacedBox>
            <SpacedBox marginRight="20px">
              <LanguageMenu />
            </SpacedBox>
            <SpacedBox marginRight="20px">
              <ConnectMetaMask />
            </SpacedBox>
            <SpacedBox>
              <ProfileMenu />
            </SpacedBox>
          </Box>
        </Box>

        // TODO: RELEASED
        // <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
        //   <SpacedBox spaceRight="32px" display="flex" alignItems="left">
        //     <StyledLink to="/collection" color={THEME.DARK_GREY}>
        //       {t('navbar.collection')}
        //     </StyledLink>
        //     <StyledLink to="/collection/1/product" color={THEME.DARK_GREY}>
        //       {t('navbar.about-product')}
        //     </StyledLink>
        //     <StyledLink to="/project" color={THEME.DARK_GREY}>
        //       {t('navbar.about-project')}
        //     </StyledLink>
        //     <StyledLink to="/faq" color={THEME.DARK_GREY}>
        //       {t('navbar.faq')}
        //     </StyledLink>
        //     <StyledLink to="/claim" color={THEME.DARK_GREY}>
        //       {t('navbar.claim-real-product')}
        //     </StyledLink>
        //   </SpacedBox>

        //   <Box display="flex" marginRight="60px">
        //     <SpacedBox spaceRight="20px" display="flex" alignItems="left">
        //       <NLink to="/collection/1/boxes">
        //         <ThemeBtn padding="11px 24px">{t('navbar.buy-now')}</ThemeBtn>
        //       </NLink>
        //       <LanguageMenu />
        //       <CurrencyMenu />
        //       <ConnectMetaMask />
        //       <ProfileMenu />
        //     </SpacedBox>
        //   </Box>
        // </Box>
      )}
    </Container>
  )
}

export default React.memo(NavigationBar)
