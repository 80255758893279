import { useCallback, useEffect } from 'react'

export default function useDebounce(effect: any, delay: number) {
  const callback = useCallback(effect, [effect])

  useEffect(() => {
    const timeout = setTimeout(callback, delay)
    return () => clearTimeout(timeout)
  }, [callback, delay])
}
