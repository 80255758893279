import { Product, Utility } from '@/models/collection'

import axios from '../axios'

export const getCollectionProductsById = async (
  id: string,
  page: number = 1,
  limit: string | number = 20,
  keyword: string = '',
) => {
  const { data } = await axios.get(`/collections/${id}/products?page=${page}&limit=${limit}&keyword=${keyword}`)

  return data
}

export const getCollectionTransactions = async (id: string, page: number = 1, limit: string | number = 20) => {
  const { data } = await axios.get(`/collections/${id}/transactions?page=${page}&limit=${limit}`)

  return data
}

export const getCollectionProduct: (id: string, productId: string) => Promise<Product> = async (id, productId) => {
  const { data } = await axios.get(`/collections/${id}/products/${productId}`)

  return data.result
}

export const claimUtility: (
  id: string,
  productId: string,
  utilityId: number,
  forceExpire?: boolean,
) => Promise<Utility> = async (id, productId, utilityId, forceExpire = false) => {
  const { data } = await axios.post(`/collections/${id}/products/${productId}/claim`, {
    utilityId,
    forceExpire,
  })

  return data
}

export const getTime: () => Promise<string> = async () => {
  const { data } = await axios.get('/time')

  return data.data.time
}
