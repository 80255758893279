import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { Size } from '@/styles'

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${({ color }: any) => (color ? color : 'white')};
  font-size: ${Size.XS.fontSize};
  line-height: ${Size.XS.lineHeight};
  font-weight: ${Size.XS.fontWeight};
`
