import { Box } from '@mui/system'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import Section1Banner from '@/assets/images/about-product/section1_banner.png'
import Section2Banner from '@/assets/images/about-product/section2_banner.png'
import Section3Banner from '@/assets/images/about-product/section3_banner.png'
import QuoteImage from '@/assets/images/common/quote.png'
import TraitsHorizontal from '@/assets/images/common/traits_horizontal.png'
import TraitsHorizontalTh from '@/assets/images/common/traits_horizontal_th.png'
import TraitsVertical from '@/assets/images/common/traits_vertical.png'
import TraitsVerticalTh from '@/assets/images/common/traits_vertical_th.png'
import CenterBanner from '@/components/CenterBanner'
import ProjectDate from '@/components/ProjectDate/ProjectDate'
import { ParseT } from '@/components/common/ParseT'
import { WContainer } from '@/components/common/WContainer'
import { LANG } from '@/constants/language'
import { useResponsive } from '@/hooks/useResponsive'
import { Size, Subtitle, Title } from '@/styles'

const ProductPage = () => {
  // const { id } = useParams()
  const { t, i18n } = useTranslation()
  const textIndent = i18n.language === LANG.TH ? '2em' : '0'
  const [searchParams, setSearchParams] = useSearchParams()
  const storyRef = React.useRef<HTMLElement>(null)
  const projectDateRef = React.useRef<HTMLElement>(null)
  const traitRef = React.useRef<HTMLElement>(null)

  React.useEffect(() => {
    setTimeout(() => {
      const q = searchParams.get('q')
      const offset = 72

      switch (q) {
        case 'story':
          window.scroll({ top: (storyRef?.current?.offsetTop ?? 0) + offset, behavior: 'smooth' })
          break
        case 'projectdate':
          window.scroll({ top: (projectDateRef?.current?.offsetTop ?? 0) - offset, behavior: 'smooth' })
          break
        case 'trait':
          window.scroll({ top: (traitRef?.current?.offsetTop ?? 0) - offset, behavior: 'smooth' })
          break
      }

      setSearchParams({})
    }, 100)
  }, [searchParams, setSearchParams])

  return (
    <Box>
      <Box
        padding={useResponsive({ desktop: '60px', tablet: '24px' })}
        style={{
          background: 'linear-gradient(0deg, #F5EDD5 0%, rgba(218, 224, 244, 0) 100%)',
        }}
        ref={storyRef}
      >
        <WContainer>
          <Box display="flex" justifyContent="space-between">
            <Box>
              <Title>{t('about-product.header.title')}</Title>
              <Box
                {...useResponsive({ desktop: Size.L, tablet: Size.M })}
                margin={useResponsive({ desktop: '24px 0 80px', tablet: '24px 0 32px' })}
              >
                {t('about-product.header.description')}
              </Box>
            </Box>
            {/* TODO: RELEASED */}
            {/* <SpacedBox display="flex" spaceRight="24px">
              <NLink to={`/collection/${id}/gallery`}>
                <ThemeBtn invert={true}>{t('about-product.header.button.view-collection')}</ThemeBtn>
              </NLink>
              <NLink to={`/collection/${id}/boxes`}>
                <ThemeBtn>{t('about-product.header.button.buy-now')}</ThemeBtn>
              </NLink>
            </SpacedBox> */}
          </Box>
          <Box
            display="flex"
            flexDirection={useResponsive({ desktop: 'row', custom: { maxWidth: '1100px', value: 'column' } })}
            marginBottom={useResponsive({ desktop: '80px', custom: { maxWidth: '1100px', value: '40px' } })}
          >
            <img src={Section1Banner} alt="product" style={{ height: '100%', width: '100%' }} />
            <Box margin={useResponsive({ desktop: '0 0 0 112px', custom: { maxWidth: '1100px', value: '32px 0' } })}>
              <Subtitle
                margin={useResponsive({
                  desktop: '0 40px 40px 0',
                  custom: { maxWidth: '1100px', value: '24px 0 24px 0' },
                })}
              >
                {t('about-product.story.title')}
              </Subtitle>
              <Box marginBottom="16px" style={{ textIndent }}>
                {ParseT('about-product.story.description1')}
              </Box>
              <Box marginBottom="16px" style={{ textIndent }}>
                {ParseT('about-product.story.description2')}
              </Box>
              <Box style={{ textIndent }}>{ParseT('about-product.story.description3')}</Box>
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection={useResponsive({ desktop: 'row', custom: { maxWidth: '1100px', value: 'column-reverse' } })}
          >
            <Box marginBottom="16px">
              <Subtitle
                margin={useResponsive({
                  desktop: '0 40px 40px 0',
                  custom: { maxWidth: '1100px', value: '32px 0 32px 0' },
                })}
              >
                {t('about-product.story2.title')}
              </Subtitle>
              <Box style={{ textIndent }}>{ParseT('about-product.story2.description')}</Box>
            </Box>
            <img
              src={Section2Banner}
              alt="product"
              style={{
                height: '100%',
                width: '100%',
                marginLeft: useResponsive({ desktop: '120px', custom: { maxWidth: '1100px', value: '0' } }),
              }}
            />
          </Box>
        </WContainer>
      </Box>
      <Box padding="80px 10vw">
        <Box display="flex" justifyContent="center" alignItems="baseline" marginBottom="60px">
          <img
            src={QuoteImage}
            alt="quote"
            style={{
              height: '64px',
              width: '64px',
              marginRight: '64px',
            }}
          />
          <Subtitle marginTop="15px" whiteSpace="pre-line">
            {t('about-product.quote.title')}
          </Subtitle>
        </Box>
        <Box textAlign="center" {...Size.XXL} fontWeight="bold">
          {t('about-product.quote.description')}
        </Box>
      </Box>
      <CenterBanner src={Section3Banner} />
      <Box ref={projectDateRef}>
        <ProjectDate />
      </Box>
      <WContainer ref={traitRef}>
        <img
          src={useResponsive({
            desktop: i18n.language === LANG.TH ? TraitsHorizontalTh : TraitsHorizontal,
            tablet: i18n.language === LANG.TH ? TraitsVerticalTh : TraitsVertical,
          })}
          alt="traits"
          style={{ width: '100%', height: '100%', display: 'block' }}
        />
      </WContainer>
    </Box>
  )
}

export default React.memo(ProductPage)
