import axios from '../axios'

export const login = async (walletId: string, req: any) => {
  const { data } = await axios.post(`/auth/login/${walletId}`, req)

  return data
}

export const getLogin = async (walletId: string) => {
  const { data } = await axios.get(`/auth/login/${walletId}`)

  return data
}

export const verifyToken = async () => {
  const { data } = await axios.get(`/auth/verify`)

  return data
}

export const logout = async (walletId: string) => {
  const { data } = await axios.post(`/auth/logout/${walletId}`, {})

  return data
}
