import { CircularProgress, Modal } from '@mui/material'
import { Box } from '@mui/system'
import { getDownloadURL, ref } from 'firebase/storage'
import { t } from 'i18next'
import moment from 'moment'
import React from 'react'
import { React360Viewer } from 'react-360-product-viewer'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.css'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { getCollectionProduct } from '@/apis/collections'
import g1 from '@/assets/images/collection-product/g_1_sq.jpg'
import g2 from '@/assets/images/collection-product/g_2_sq.jpg'
import g3 from '@/assets/images/collection-product/g_3_sq.jpg'
import g4 from '@/assets/images/collection-product/g_4_sq.jpg'
import g5 from '@/assets/images/collection-product/g_5_sq.jpg'
import pg1 from '@/assets/images/collection-product/pg_1_sq.jpg'
import pg2 from '@/assets/images/collection-product/pg_2_sq.jpg'
import pg3 from '@/assets/images/collection-product/pg_3_sq.jpg'
import pg4 from '@/assets/images/collection-product/pg_4_sq.jpg'
import pg5 from '@/assets/images/collection-product/pg_5_sq.jpg'
import BackButton from '@/components/common/BackButton'
import Table from '@/components/common/Table'
import ThemeBtn from '@/components/common/ThemeBtn'
import WalletIdBox from '@/components/common/WalletIdBox'
import configs from '@/configs'
import { metaMaskHooks } from '@/connectors/metamask'
import { THEME } from '@/constants/color'
import { useCustomQuery, useResponsive } from '@/hooks/useResponsive'
import { Product, Utility } from '@/models/collection'
import { Size } from '@/styles'
import { truncateString } from '@/utils'

import { storage } from '../../firebase'
import UtilityModal from './components/UtilityModal'
import { fallbackProductId } from './data'

const { useAccounts } = metaMaskHooks

const smallScreenSize = '1200px'
const size = 672
const width = size
const height = size

const CollectionProductPage = () => {
  const navigate = useNavigate()
  const [screenWidth, setScreenWidth] = React.useState<number>(window.innerWidth)
  const [imageUrls, setImageUrls] = React.useState<string[]>([])
  const [product, setProduct] = React.useState<Product>()
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false)
  const [isLoading, setIsLoading] = React.useState<boolean>(true)

  const accounts = useAccounts()
  const walletId = React.useMemo(() => accounts?.[0], [accounts])

  // const navigate = useNavigate()
  const location = useLocation()
  const { id: collectionId, productId } = useParams()

  const isSmallScreen = useCustomQuery(smallScreenSize)

  React.useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const fetchImages = React.useCallback(() => {
    const imageRef = ref(storage, `collections/${collectionId}/${productId}/${productId}-1.png`)
    getDownloadURL(imageRef).then(url => {
      // treat a full storage path as a file name
      let split = url.split(`collections`)
      const baseUrl = split[0]
      split = ('collections' + split[1]).split('1.')
      const filenamePrefix = split[0]
      const filetype = split[1]
      setImageUrls([baseUrl, filenamePrefix, filetype])
    })
    // .catch(() => navigate(location.pathname.split('/product')[0]))
  }, [collectionId, productId])

  const fetchProduct = React.useCallback(async () => {
    if (!collectionId || !productId) return null
    try {
      return await getCollectionProduct(collectionId, productId)
    } catch (err) {
      return null
    }
  }, [collectionId, productId])

  React.useEffect(() => {
    if (productId && fallbackProductId[productId]) {
      navigate(`/collection/${collectionId}/product/${fallbackProductId[productId]}`)
    }

    fetchProduct().then(result => {
      if (result) {
        fetchImages()
        setProduct(result)
      }
      setIsLoading(false)
    })
  }, [collectionId, fetchImages, fetchProduct, navigate, productId])

  const [imagesBaseUrl, imageFilenamePrefix, imagesFiletype] = imageUrls
  const { token_id, metadata, buyer_address, createdAt, utilities } = product ?? {}
  const { attributes } = metadata ?? {}
  const { material, color, yantra } = attributes ?? {}
  const skuNo = `#${collectionId}VS${material?.charAt(0)}${productId}`

  const updateUtility = React.useCallback(
    (utility: Utility) => {
      if (product) {
        setProduct({
          ...product,
          utilities: {
            ...utilities,
            [utility.utility_id]: {
              utility_id: utility.utility_id,
              claimed_date: utility.claimed_date,
            },
          },
        })
      }
    },
    [product, utilities],
  )

  const containerDisplay = useResponsive({ desktop: 'flex', custom: { maxWidth: smallScreenSize, value: 'block' } })
  const subContainerTextAlign = useResponsive({
    desktop: undefined,
    custom: { maxWidth: smallScreenSize, value: 'center' },
  })
  const productDetailMarginLeft = useResponsive({ desktop: '80px', custom: { maxWidth: smallScreenSize, value: '0' } })

  return (
    <>
      <Box
        color={THEME.BLUE}
        padding={useResponsive({ desktop: '64px', custom: { maxWidth: smallScreenSize, value: '24px' } })}
        style={{ background: 'linear-gradient(360deg, #EAEAEA 0%, rgba(234, 234, 234, 0) 93.85%)' }}
      >
        <BackButton
          label={t('collection-product.button.back')}
          to={location.pathname.split('/product')[0]}
          marginBottom="64px"
        />
        {!product ? (
          <Box textAlign="center">
            {isLoading ? (
              <CircularProgress size="120px" sx={{ color: 'white' }} />
            ) : (
              <Box {...Size.XXL}>Product not found</Box>
            )}
          </Box>
        ) : (
          <>
            <Box display={containerDisplay} marginBottom="64px">
              <Box textAlign={subContainerTextAlign} marginBottom={isSmallScreen ? '24px' : undefined}>
                {!imagesBaseUrl || !imageFilenamePrefix || !imagesFiletype ? (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    width={width + 'px'}
                    height={height + 'px'}
                  >
                    <CircularProgress size="48px" sx={{ color: 'white' }} />
                  </Box>
                ) : (
                  <Carousel
                    showArrows={true}
                    width={`${isSmallScreen ? screenWidth - 48 : height}px`}
                    showStatus={false}
                  >
                    <Box height={isSmallScreen ? screenWidth - 48 : height} overflow="hidden">
                      <React360Viewer
                        imagesBaseUrl={imagesBaseUrl}
                        imageFilenamePrefix={imageFilenamePrefix}
                        imagesCount={36}
                        imagesFiletype={imagesFiletype}
                        mouseDragSpeed={20}
                        width={isSmallScreen ? screenWidth - 48 : width}
                        height={isSmallScreen ? screenWidth - 48 : height}
                        showRotationIconOnStartup
                      />
                      <img src={`${imagesBaseUrl}${imageFilenamePrefix}1.${imagesFiletype}`} alt="3d-tempt" />
                    </Box>
                    {(productId?.replace(/[0-9]/g, '') === 'BG' ? [g1, g2, g3, g4, g5] : [pg1, pg2, pg3, pg4, pg5]).map(
                      (x, i) => (
                        <div>
                          <img src={x} alt={`${productId}-${i}`} />
                        </div>
                      ),
                    )}
                  </Carousel>
                )}
              </Box>
              <Box
                marginLeft={productDetailMarginLeft}
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
              >
                <Box>
                  <Box margin={isSmallScreen ? 'auto' : undefined}>
                    <Box {...Size.L}>{t('collection-product.product.title')}</Box>
                    <Box {...Size.XXL} fontWeight="bold" marginBottom={isSmallScreen ? '20px' : '16px'}>
                      {productId?.replace(/[0-9]/g, '')}#{token_id}
                    </Box>
                  </Box>
                  <Box marginTop={isSmallScreen ? '20px' : undefined} marginBottom="20px">
                    {t('collection-product.product.description')}
                  </Box>
                  <Box>Material: {material}</Box>
                  <Box>Color: {color}</Box>
                  <Box>Yantra: {yantra}</Box>
                  <Box>Number: {token_id}</Box>
                  <Box>Size: {size}</Box>
                </Box>
                <Box>
                  <Box marginBottom="24px">
                    <Box {...Size.L}>{t('collection-product.product.owner')}</Box>
                    <Box>
                      <WalletIdBox walletId={buyer_address ?? '0x0'} />
                    </Box>
                  </Box>
                  {configs.ENABLE_REGISTER_PRIVILEGES &&
                    walletId &&
                    buyer_address &&
                    walletId.toLowerCase() === buyer_address.toLowerCase() && (
                      <ThemeBtn
                        style={{ width: 'fit-content' }}
                        onClick={() => utilities && collectionId && productId && setIsModalOpen(true)}
                      >
                        {t('collection-product.button.action.owner')}
                      </ThemeBtn>
                    )}
                </Box>
              </Box>
            </Box>
            <Box display={isSmallScreen ? 'block' : 'flex'}>
              <Box>
                <Box {...Size.L} marginBottom="24px">
                  Attribute
                </Box>
                <Box bgcolor="white" padding="32px 20px" width="312px">
                  <Box display="flex" marginBottom="32px">
                    <Box color={THEME.DARK_GREY} marginRight="32px">
                      MATERIAL
                    </Box>
                    <Box textAlign="right" marginLeft="auto">
                      <Box>{material}</Box>
                      <Box {...Size.XXS} color={THEME.DARK_GREY}>
                        100% HAVE THIS TRAITS
                      </Box>
                    </Box>
                  </Box>
                  <Box display="flex" marginBottom="32px">
                    <Box color={THEME.DARK_GREY} marginRight="32px">
                      COLOR
                    </Box>
                    <Box textAlign="right" marginLeft="auto">
                      <Box>{color}</Box>
                      <Box {...Size.XXS} color={THEME.DARK_GREY}>
                        50% HAVE THIS TRAITS
                      </Box>
                    </Box>
                  </Box>
                  <Box display="flex" marginBottom="32px">
                    <Box color={THEME.DARK_GREY} marginRight="32px">
                      YANTRA
                    </Box>
                    <Box textAlign="right" marginLeft="auto">
                      <Box>{yantra}</Box>
                      <Box {...Size.XXS} color={THEME.DARK_GREY}>
                        100% HAVE THIS TRAITS
                      </Box>
                    </Box>
                  </Box>
                  <Box display="flex">
                    <Box color={THEME.DARK_GREY} marginRight="32px">
                      NUMBER
                    </Box>
                    <Box textAlign="right" marginLeft="auto">
                      <Box>{token_id}</Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box marginLeft={isSmallScreen ? 0 : '24px'} width="100%">
                <Box {...Size.L} marginBottom="24px">
                  Activity
                </Box>
                <Table
                  titles={['OWNED BY', 'MARKET PLACE', 'DATE']}
                  rows={[
                    ...[
                      buyer_address && createdAt
                        ? [truncateString(buyer_address), 'Devaloka', moment(createdAt).calendar()]
                        : [],
                    ],
                  ]}
                />
              </Box>
            </Box>
          </>
        )}
      </Box>
      {utilities && collectionId && productId && (
        <Modal open={isModalOpen}>
          <UtilityModal
            utilities={Object.values(utilities)}
            skuNo={skuNo}
            collectionId={collectionId}
            productId={productId}
            onClose={() => setIsModalOpen(false)}
            updateUtility={updateUtility}
          />
        </Modal>
      )}
    </>
  )
}

export default React.memo(CollectionProductPage)
