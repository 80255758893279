import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Box } from '@mui/material'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { THEME } from '@/constants/color'

export const BackIcon = styled(ArrowBackIcon)`
  padding: 12px 12px 1px 12px;
  font-color: ${THEME.BLUE};
`

const Button = styled.button`
  padding: 0;
  border: 1px solid ${THEME.BLUE};
  border-radius: 2px;
  background: #ffffff;
  cursor: pointer;
  margin-right: 16px;
  font-size: 21px;
`

interface BackButtonProps {
  label?: string
  to?: string
  marginBottom?: string
  onClick?: () => void
}

const BackButton = ({ label, to, marginBottom, onClick }: BackButtonProps) => {
  const navigate = useNavigate()

  return (
    <Box display="flex" marginBottom={marginBottom ?? '80px'}>
      <Button onClick={onClick ? onClick : () => (to ? navigate(to) : navigate(-1))}>
        <BackIcon fontSize="inherit" />
      </Button>
      <Box display="flex" alignItems="center" justifyContent="center">
        {label ?? 'Back'}
      </Box>
    </Box>
  )
}

export default React.memo(BackButton)
