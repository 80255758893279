import { Box } from '@mui/system'
import type { AddEthereumChainParameter } from '@web3-react/types'
import React, { useEffect, useMemo } from 'react'
import { Provider, positions } from 'react-alert'
import { useTranslation } from 'react-i18next'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'

import { getLogin, login, logout, verifyToken } from '@/apis/user/auth'
import '@/assets/fonts/Boon-Bold.ttf'
import '@/assets/fonts/Boon-Light.ttf'
import '@/assets/fonts/Boon-Medium.ttf'
import '@/assets/fonts/Boon-Regular.ttf'
import '@/assets/fonts/Boon-SemiBold.ttf'
import '@/assets/fonts/NotoSerifSC-Bold.otf'
import '@/assets/fonts/NotoSerifSC-Light.otf'
import '@/assets/fonts/NotoSerifSC-Medium.otf'
import '@/assets/fonts/NotoSerifSC-Regular.otf'
import '@/assets/fonts/NotoSerifSC-SemiBold.otf'
import Footer from '@/components/Footer/Footer'
import NavigationBar from '@/components/NavigationBar'
import Alert from '@/components/common/Alert'
import { GoToTopButton } from '@/components/common/GoToTopButton'
import ScrollToTop from '@/components/common/ScrollToTop'
import { metaMask, metaMaskHooks } from '@/connectors/metamask'
import CollectionGalleryPage from '@/pages/collection/CollectionGalleryPage'
import CollectionProductPage from '@/pages/collection/CollectionProductPage'
// import Faq from '@/pages/faq'
import HomePage from '@/pages/home'
import ProductPage from '@/pages/product'
import ProjectPage from '@/pages/project'
import Userprofile from '@/pages/userprofile'
import Usersettings from '@/pages/usersettings'

import { getAddChainParameters } from './functions/chains'
import { usePrevious } from './hooks/usePrevious'
import GlobalStyle from './styled'
import { getChainId } from './utils'

const { useAccounts, useProvider } = metaMaskHooks

const App = () => {
  const { i18n } = useTranslation()
  const provider = useProvider()
  const accounts = useAccounts()
  const walletId = useMemo(() => accounts?.[0], [accounts])
  const chainId = getChainId()
  const chainParam = getAddChainParameters(chainId)
  const prevWalletId = usePrevious(walletId)

  useEffect(() => {
    if (!walletId) return
    if (provider) {
      const fetch = async () => {
        try {
          await verifyToken()
          return
        } catch (error) {
          console.log(error)
        }

        try {
          const msg = await getLogin(walletId)
          const signer = provider.getSigner()
          const messageStr = JSON.stringify(msg)

          const signature = await signer.signMessage(messageStr)
          await login(walletId, { signature })
          window.location.reload()
        } catch (error) {
          console.log(error)
        }
      }
      fetch()
    }
  }, [walletId, provider])

  useEffect(() => {
    const fetch = async () => {
      if (prevWalletId && prevWalletId !== walletId) {
        await metaMask.deactivate()
        await logout(prevWalletId)
      }
    }
    fetch()
  }, [prevWalletId, walletId])

  useEffect(() => {
    const selectNetwork = async () => {
      if (window && window.ethereum) {
        const chainIdHex = `0x${chainId.toString(16)}`
        try {
          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: chainIdHex }],
          })
        } catch (error: any) {
          if (error.code === 4902) {
            const c = chainParam as AddEthereumChainParameter
            window.ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainId: chainIdHex,
                  rpcUrls: c.rpcUrls,
                  chainName: c.chainName,
                  nativeCurrency: c.nativeCurrency,
                  blockExplorerUrls: c.blockExplorerUrls,
                },
              ],
            })
          } else {
            console.log(error)
          }
        }
      }
    }
    selectNetwork()
  }, [chainId, chainParam])

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Provider
        template={Alert}
        position={positions.TOP_CENTER}
        timeout={5000}
        containerStyle={{
          marginTop: '32px',
          zIndex: 99999,
        }}
      >
        <GlobalStyle lang={i18n.language} />
        <NavigationBar />
        <Box paddingTop="80px" />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="project" element={<ProjectPage />} />
          <Route path="collection/:id" element={<CollectionGalleryPage />} />
          <Route path="collection/:id/product" element={<ProductPage />} />
          <Route path="collection/:id/product/:material/:productId" element={<CollectionProductPage />} />
          <Route path="collection/:id/product/:productId" element={<CollectionProductPage />} />
          <Route path="collection/:material/:id" element={<ProductPage />} />
          <Route path="user/profile" element={<Userprofile />} />
          <Route path="user/profile/settings" element={<Usersettings />} />

          {/* <Route path="faq" element={<Faq />} /> */}

          <Route path="*" element={<Navigate to="/" />} />
        </Routes>

        {/* TODO: RELEASED */}
        {/* <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="project" element={<ProjectPage />} />
          <Route path="collection" element={<Collection />} />
          <Route path="collection/:id/gallery" element={<CollectionGalleryPage />} />
          <Route path="collection/:id/product" element={<ProductPage />} />
          <Route path="collection/:id/boxes" element={<CollectionBoxPage />} />
          <Route path="collection/:id/boxes/:boxId" element={<CollectionBuyPage />} />
          <Route path="collection/:id/boxes/:boxId/buy" element={<CollectionConfirmBuyPage />} />

          <Route path="claim" element={<ClaimPage />} />
          <Route path="claim/request" element={<ClaimRequestPage />} />

          <Route path="user/profile" element={<UserProfile />} />
          <Route path="user/profile/settings" element={<UserSettings />} />

          <Route path="nftverify" element={<NFTVerifyPage />} />
          <Route path="faq" element={<Faq />} />

          <Route path="*" element={<Navigate to="/" />} />
        </Routes> */}
        <GoToTopButton />
        <Footer />
      </Provider>
    </BrowserRouter>
  )
}

export default React.memo(App)
