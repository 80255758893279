import { Box, Table as MuiTable, Paper, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import * as React from 'react'

import { Size } from '@/styles'

interface TableProps {
  titles: string[]
  rows: (JSX.Element | string | number)[][]
}

const Table = ({ titles, rows }: TableProps) => {
  return (
    <TableContainer component={Paper}>
      <MuiTable>
        <TableHead style={{ backgroundColor: '#DFDFDF' }}>
          <TableRow>
            {titles.map((title, i) => (
              <TableCell align="center" key={i}>
                <Box {...Size.XXS} fontWeight={600}>
                  {title}
                </Box>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((rowItems, i) => (
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={i}>
              {rowItems.map((item, index) => (
                <TableCell align="center" key={index}>
                  <Box>{item}</Box>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </MuiTable>
    </TableContainer>
  )
}

export default React.memo(Table)
