import { Box } from '@mui/system'
import { useTranslation } from 'react-i18next'

import Section1Q1Image from '@/assets/images/home/section1_q1.png'
import Section1Q2Image from '@/assets/images/home/section1_q2.png'
import Section1Q3Image from '@/assets/images/home/section1_q3.png'
import Section1Q4Image from '@/assets/images/home/section1_q4.png'
import { THEME } from '@/constants/color'
import { useResponsive } from '@/hooks/useResponsive'
import { Size } from '@/styles'

type Props = {
  index: string
}

const Section1Content = ({ index }: Props) => {
  const { t } = useTranslation()

  const getSrc = () => {
    switch (index) {
      case '1':
        return Section1Q1Image
      case '2':
        return Section1Q2Image
      case '3':
        return Section1Q3Image
      case '4':
        return Section1Q4Image
    }
  }

  return (
    <Box flex="1" marginBottom={useResponsive({ tablet: '24px', desktop: '0px' })} marginRight="24px">
      <img src={getSrc()} alt="box" width="32px" />
      <Box
        {...useResponsive({ desktop: Size.L, tablet: Size.M })}
        fontWeight="bold"
        color={THEME.DARK_GREY_2}
        marginTop="16px"
      >
        {t(`home.section1.q${index}`)}
      </Box>
      <Box color={THEME.DARK_GREY_2}>{t(`home.section1.a${index}`)}</Box>
    </Box>
  )
}

export default Section1Content
