import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/system'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import styled from 'styled-components'

import { THEME } from '@/constants/color'

import Slide from './Slide'

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

const NavigationButtons = styled.div`
  position: relative;
  display: flex;
  height: 40px;
  margin: 0 auto;
  margin-top: 4rem;
  align-items: center;
  justify-content: center;
  div {
    height: 100%;
  }

  @media (max-width: 768px) {
    margin-top: 10rem;
  }

  @media (max-width: 579px) {
    margin-top: 7rem;
  }

  @media (max-width: 390px) {
    margin-top: 5rem;
  }
`

const DEFAULT_GO_TO_SLIDE_DELAY = 200

interface IState {
  index: number
  goToSlide: number | null
  prevPropsGoToSlide: number
  newSlide: boolean
}

interface IProps {
  slides: ISlide[]
  goToSlide?: number
  offsetRadius: number
  animationConfig: object
  goToSlideDelay: number
  opacity: number
  autoPlay: boolean
  interval: number
}

interface ISlide {
  key: React.Key
  content: JSX.Element
  onClick?: () => void
}

function mod(a: number, b: number): number {
  return ((a % b) + b) % b
}

class Carousel extends Component<IProps, IState> {
  state: IState = {
    index: 0,
    goToSlide: null,
    prevPropsGoToSlide: 0,
    newSlide: false,
  }

  goToIn?: number

  static propTypes = {
    slides: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.any,
        content: PropTypes.object,
      }),
    ).isRequired,
    goToSlide: PropTypes.number,
    offsetRadius: PropTypes.number,
    animationConfig: PropTypes.object,
    goToSlideDelay: PropTypes.number,
    opacity: PropTypes.number,
    autoPlay: PropTypes.bool,
    interval: PropTypes.number,
  }

  static defaultProps = {
    offsetRadius: 2,
    animationConfig: { tension: 120, friction: 14 },
    goToSlideDelay: DEFAULT_GO_TO_SLIDE_DELAY,
    opacity: null,
    autoPlay: false,
    interval: 5,
  }

  static getDerivedStateFromProps(props: IProps, state: IState) {
    const { goToSlide } = props

    if (goToSlide !== state.prevPropsGoToSlide) {
      return { prevPropsGoToSlide: goToSlide, goToSlide, newSlide: true }
    }

    return null
  }

  componentDidMount() {
    const { autoPlay, interval } = this.props
    if (autoPlay) {
      setInterval(() => {
        this.moveSlide(1)
      }, interval * 1000)
    }
  }

  componentDidUpdate() {
    const { goToSlideDelay } = this.props
    const { index, goToSlide, newSlide } = this.state
    if (typeof goToSlide === 'number') {
      if (newSlide) {
        this.handleGoToSlide()
      } else if (index !== goToSlide && typeof window !== 'undefined') {
        window.clearTimeout(this.goToIn)
        this.goToIn = window.setTimeout(this.handleGoToSlide, goToSlideDelay)
      } else if (typeof window !== 'undefined') {
        window.clearTimeout(this.goToIn)
      }
    }
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      window.clearTimeout(this.goToIn)
    }
  }

  modBySlidesLength = (index: number): number => {
    return mod(index, this.props.slides.length)
  }

  moveSlide = (direction: -1 | 1) => {
    this.setState({
      index: this.modBySlidesLength(this.state.index + direction),
      goToSlide: null,
    })
  }

  getShortestDirection(from: number, to: number): -1 | 0 | 1 {
    if (from > to) {
      if (from - to > this.props.slides.length - 1 - from + to) {
        return 1
      } else return -1
    } else if (to > from) {
      if (to - from > from + this.props.slides.length - 1 - to) {
        return -1
      } else return 1
    }
    return 0
  }

  handleGoToSlide = () => {
    if (typeof this.state.goToSlide !== 'number') {
      return
    }

    const { index } = this.state

    const goToSlide = mod(this.state.goToSlide, this.props.slides.length)

    if (goToSlide !== index) {
      const direction = this.getShortestDirection(index, goToSlide)
      const isFinished = this.modBySlidesLength(index + direction) === goToSlide

      this.setState({
        index: this.modBySlidesLength(index + direction),
        newSlide: isFinished,
        goToSlide: isFinished ? null : goToSlide,
      })
    }
  }

  clampOffsetRadius(offsetRadius: number): number {
    const { slides } = this.props
    const upperBound = Math.floor((slides.length - 1) / 2)

    if (offsetRadius < 0) {
      return 0
    }
    if (offsetRadius > upperBound) {
      return upperBound
    }

    return offsetRadius
  }

  getPresentableSlides(): ISlide[] {
    const { slides } = this.props
    const { index } = this.state
    let { offsetRadius } = this.props
    offsetRadius = this.clampOffsetRadius(offsetRadius)
    const presentableSlides: ISlide[] = []

    for (let i = -offsetRadius; i < 1 + offsetRadius; i++) {
      presentableSlides.push(slides[this.modBySlidesLength(index + i)])
    }

    return presentableSlides
  }

  render() {
    const { animationConfig, offsetRadius, opacity } = this.props

    return (
      <React.Fragment>
        <Wrapper>
          {this.getPresentableSlides().map((slide: ISlide, presentableIndex: number) => (
            <Slide
              key={slide.key}
              content={slide.content}
              onClick={slide.onClick}
              offsetRadius={this.clampOffsetRadius(offsetRadius)}
              index={presentableIndex}
              animationConfig={animationConfig}
              opacity={opacity}
            />
          ))}
        </Wrapper>
        <NavigationButtons>
          <Box>
            <FontAwesomeIcon
              icon={faChevronLeft}
              color={THEME.LIGHT_BLUE}
              size="2x"
              onClick={() => this.moveSlide(-1)}
              style={{ marginRight: '2rem', cursor: 'pointer' }}
            />
          </Box>
          <Box>
            <FontAwesomeIcon
              icon={faChevronRight}
              color={THEME.LIGHT_BLUE}
              size="2x"
              onClick={() => this.moveSlide(1)}
              style={{ marginLeft: '2rem', cursor: 'pointer' }}
            />
          </Box>
        </NavigationButtons>
      </React.Fragment>
    )
  }
}

export default Carousel
