import { BigNumber } from '@ethersproject/bignumber'
import { faCog } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/system'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { getMetadataFromUrl } from '@/apis/contracts'
import { getUser } from '@/apis/user/profile'
import { ReactComponent as UserProfileSVG } from '@/assets/images/profile-btn.svg'
import ProductCard from '@/components/ProductCard'
import { CenterContainer } from '@/components/common/CenterContainer'
import ThemeBtn from '@/components/common/ThemeBtn'
import WalletIdBox, { CopyBtn } from '@/components/common/WalletIdBox'
import { metaMask, metaMaskHooks } from '@/connectors/metamask'
import { THEME } from '@/constants/color'
import { connectMetamask } from '@/functions/connectWallet'
import { useNFTContract } from '@/hooks/useContract'
import { useResponsive, useTabletQuery } from '@/hooks/useResponsive'
import { Token } from '@/models/collection'
import User from '@/models/user'
import { Size } from '@/styles'
import { openLineShop } from '@/utils'

import { MyCollectionContainer, ProfilePicture, ProfileSettingBtn } from './styled'

const { useAccounts } = metaMaskHooks

const UserProfile = () => {
  const accounts = useAccounts()
  const nftContract = useNFTContract()
  const connectWallet = () => connectMetamask(metaMask)
  const [userNfts, setUserNfts] = useState<Token[]>([])
  const walletId = React.useMemo(() => accounts?.[0], [accounts])
  const [user, setUser] = React.useState<User>()
  const { t } = useTranslation()
  const tinyProductCard = useResponsive({ desktop: false, tablet: true })
  const gridTemplateColumns = useResponsive({ desktop: 'repeat(4, 1fr)', smallScreen: 'repeat(2, 1fr)' })
  const gridGap = useResponsive({ desktop: '48px', tablet: '20px' })
  const isTablet = useTabletQuery()

  useEffect(() => {
    if (!walletId) return
    getUser().then(resp => {
      const { data } = resp
      setUser({
        name: data.name,
        email: data.email,
        profile_picture_url: data.profile_picture_url,
      })
    })
  }, [walletId])

  const getUserNfts = useCallback(async () => {
    try {
      setUserNfts([])
      if (accounts && accounts.length > 0 && accounts[0] !== '') {
        const currentAddress = accounts[0]
        const balanceOf = await nftContract?.balanceOf(currentAddress)

        for (let i = 0; i < balanceOf; i++) {
          const tk = await nftContract?.tokenOfOwnerByIndex(currentAddress, i)
          const token = BigNumber.from(tk)
          const tokenId = token.toNumber()
          const uri = await nftContract?.tokenURI(token.toNumber())

          if (uri !== '') {
            const metadata = await getMetadataFromUrl(uri)
            setUserNfts(u => [...u, { tokenId, metadata }])
          }
        }
      }
    } catch (error) {
      console.log(error)
    }
  }, [accounts, nftContract])

  useEffect(() => {
    getUserNfts()
  }, [getUserNfts])

  return (
    <Box>
      <Box margin="96px 64px 64px" display="flex" flexDirection="column" alignItems="center">
        {accounts && accounts.length > 0 && (
          <ProfileSettingBtn to="/user/profile/settings" style={{ padding: isTablet ? '8px 12px' : '12px 48px' }}>
            {isTablet ? <FontAwesomeIcon icon={faCog} /> : t('user-profile.button.setting')}
          </ProfileSettingBtn>
        )}
        {walletId && user?.profile_picture_url ? (
          <ProfilePicture src={user?.profile_picture_url} />
        ) : (
          <UserProfileSVG
            width="192px"
            height="192px"
            style={{ padding: '14px', border: `4px solid ${THEME.LIGHT_BLUE}`, borderRadius: '50%' }}
          />
        )}
        <Box {...Size.XXL} margin="18px">
          {walletId && user?.name ? user?.name : 'Username'}
        </Box>
        {accounts && accounts.length > 0 ? (
          <WalletIdBox walletId={accounts[0]} />
        ) : (
          <CopyBtn>
            <Box onClick={connectWallet}>{t('user-profile.button.connect-wallet')}</Box>
          </CopyBtn>
        )}
      </Box>
      <MyCollectionContainer>
        <Box textAlign="center" {...Size.XXL}>
          {t('user-profile.collection.title')}
        </Box>
        {userNfts.length > 0 ? (
          <>
            {/* TODO: RELEASED */}
            {/* <Box width="100%">
                <ThemeBtn width="200px" marginLeft="auto" marginRight="0" onClick={() => navigate('/claim')}>
                  {t('user-profile.button.claim')}
                </ThemeBtn>
                <Box marginTop="64px" display="flex">
                  <Input
                    placeholder={t('user-profile.collection.search.placeholder')}
                    style={{
                      width: '70%',
                      height: '40px',
                      backgroundColor: 'white',
                      padding: '8px 12px',
                      border: '1px solid #D9D9D9',
                    }}
                  />
                  <Box style={{ marginLeft: 'auto', marginRight: '0', width: '25%' }}>
                    <FormControl fullWidth>
                      <InputLabel id="sortBy-select-label" style={{ color: '#8C8C8C', fontSize: '16px' }}>
                        {t('user-profile.collection.search.sort')}
                      </InputLabel>
                      <Select
                        labelId="sortBy-select-label"
                        id="sortBy-select"
                        label="sortBy"
                        size="small"
                        style={{ backgroundColor: 'white', fontSize: '16px', height: '40px' }}
                      >
                        <MenuItem value={1}>Popularity</MenuItem>
                        <MenuItem value={2}>Price</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
                <Box display="flex" marginTop="40px">
                  <ThemeCheckbox label={t('user-profile.collection.search.check-box.all')} />
                  <ThemeCheckbox label={t('user-profile.collection.search.check-box.claimed')} />
                  <ThemeCheckbox label={t('user-profile.collection.search.check-box.not-claimed')} />
                </Box>
              </Box> */}
            <Box
              width="100%"
              display="grid"
              marginTop="32px"
              gridTemplateColumns={gridTemplateColumns}
              alignItems="center"
              gap={gridGap}
            >
              {userNfts.map(nft => (
                <Link
                  to={`/collection/1/product/${nft.metadata.name}`}
                  style={{
                    textDecoration: 'none',
                    color: THEME.BLUE,
                  }}
                  key={nft.metadata.name}
                >
                  <ProductCard
                    image={nft.metadata.image}
                    key={nft.tokenId}
                    title={`${nft.metadata.name}`}
                    tiny={tinyProductCard}
                  />
                </Link>
              ))}
            </Box>
          </>
        ) : (
          <CenterContainer margin="auto">
            <Box marginBottom="32px">{t('user-profile.collection.empty-content.description')}</Box>
            <ThemeBtn onClick={openLineShop}>{t('user-profile.collection.empty-content.buy-button')}</ThemeBtn>
          </CenterContainer>
        )}
      </MyCollectionContainer>
    </Box>
  )
}

export default React.memo(UserProfile)
