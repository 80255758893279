import parse from 'html-react-parser'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

// ParseT use when want react component in i18n.
// auto make text in ` “” ` and ` () ` to keep in same line.
export const ParseT = (i18nKey: string) => {
  const { t } = useTranslation()
  const [text, setText] = useState('')

  useEffect(() => {
    let txt = t(i18nKey)
    txt = txt.replaceAll('“', `<span class='keeptogether'>“`)
    txt = txt.replaceAll('”', `”</span>`)
    txt = txt.replaceAll('(', `<span class='keeptogether'>(`)
    txt = txt.replaceAll(')', `)</span>`)

    setText(txt)
  }, [i18nKey, t])

  return parse(text)
}
