import { Box } from '@mui/system'
import styled from 'styled-components'

import { Size } from '@/styles'

export const CardDetail = styled(Box)`
  padding: 24px;
  font-size: ${Size.L.fontSize};
  line-height: ${Size.L.lineHeight};
  font-weight: 600;
  background-color: white;
`
