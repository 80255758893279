import { Box } from '@mui/system'
import React from 'react'
import { useAlert } from 'react-alert'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { getUser, upsertUser } from '@/apis/user/profile'
import { NLink } from '@/components/common/NLink'
import { SpacedBox } from '@/components/common/SpaceBox'
import ThemeBtn from '@/components/common/ThemeBtn'
import { metaMaskHooks } from '@/connectors/metamask'
import { useResponsive } from '@/hooks/useResponsive'
import User from '@/models/user'
import { ProfilePicture } from '@/pages/userprofile/styled'
import { Size } from '@/styles'

import { Input } from './styled'

const { useAccounts } = metaMaskHooks

const allowedFileTypes = ['image/png', 'image/jpeg', 'image/gif']

const UserSettings = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [name, setName] = React.useState<string>()
  const [profilePicture, setProfilePicture] = React.useState<any>()
  const [email, setEmail] = React.useState<string>()

  const alert = useAlert()

  const accounts = useAccounts()

  const walletId = React.useMemo(() => accounts?.[0], [accounts])

  const setUser = React.useCallback((user: User) => {
    setName(user.name)
    setProfilePicture(user.profile_picture_url)
    setEmail(user.email)
  }, [])

  React.useEffect(() => {
    if (!walletId) return
    getUser().then(response => setUser(response.data))
  }, [setUser, walletId])

  const onNameChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => setName(event.target.value),
    [],
  )

  const onEmailChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value),
    [],
  )

  const onProfilePictureChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setProfilePicture(event.target.files?.[0])
  }, [])

  // const fetchNewUserCreditCards = React.useCallback(async () => {
  //   const cards = await getUserCreditCards(walletId)

  //   setUserCreditCards(cards)
  // }, [walletId])

  // const [userCreditCards, setUserCreditCards] = React.useState<CreditCardDetails[]>([])
  // React.useEffect(() => {
  //   fetchNewUserCreditCards()
  // }, [fetchNewUserCreditCards])

  // const [modalOpen, setModalOpen] = React.useState(false)

  const onSubmit = React.useCallback(() => {
    if (!walletId) return
    const formData = new FormData()
    if (profilePicture instanceof File) {
      if (profilePicture.size > 2 * 1024 * 1024) {
        alert.error('Profile picture must be less than 2mb')
        return
      }
      if (!allowedFileTypes.includes(profilePicture.type)) {
        alert.error('Profile picture must be image')
        return
      }
      formData.append('file', profilePicture)
    }
    formData.append('email', email || '')
    formData.append('name', name || '')
    upsertUser(formData)
      .then(response => {
        setUser(response.data)
        alert.success('Saved')
        navigate('/user/profile')
      })
      .catch(error => {
        alert.error('Failed to save')
      })
  }, [alert, email, name, navigate, profilePicture, setUser, walletId])

  return (
    <Box minHeight="70vh">
      <SpacedBox maxWidth="968px" margin="auto" spaceBottom="64px" padding="96px 12px">
        <Box {...Size.XXL}>{t('user-settings.title')}</Box>
        <Box display="flex" flexDirection={useResponsive({ desktop: 'row', tablet: 'column' })}>
          <Box flexBasis={useResponsive({ desktop: '300px', tablet: '0' })} {...Size.L}>
            {t('user-settings.form.name.title')}
          </Box>
          <Box width="100%">
            <Input placeholder={t('user-settings.form.name.placeholder')} value={name} onChange={onNameChange} />
          </Box>
        </Box>
        <Box display="flex" flexDirection={useResponsive({ desktop: 'row', tablet: 'column' })}>
          <Box flexBasis={useResponsive({ desktop: '300px', tablet: '0' })} {...Size.L}>
            {t('user-settings.form.profile-picture.title')}
          </Box>
          <Box width="100%">
            <Input
              type="file"
              accept="image/*"
              placeholder="Profile"
              onChange={onProfilePictureChange}
              style={{ marginBottom: '24px' }}
            />
            {profilePicture && (
              <ProfilePicture
                src={profilePicture instanceof File ? URL.createObjectURL(profilePicture) : profilePicture}
              />
            )}
          </Box>
        </Box>
        <Box display="flex" flexDirection={useResponsive({ desktop: 'row', tablet: 'column' })}>
          <Box flexBasis={useResponsive({ desktop: '300px', tablet: '0' })} {...Size.L}>
            {t('user-settings.form.notification.title')}
            <Box>{t('user-settings.form.notification.description')}</Box>
          </Box>
          <Box width="100%">
            <Input
              placeholder={t('user-settings.form.notification.placeholder')}
              value={email}
              onChange={onEmailChange}
            />
          </Box>
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <NLink to="/user/profile">
            <ThemeBtn invert={true} marginRight="12px">
              {t('user-settings.form.button.cancel')}
            </ThemeBtn>
          </NLink>
          <ThemeBtn onClick={onSubmit}>{t('user-settings.form.button.save')}</ThemeBtn>
        </Box>
        {/* TODO: RELEASED */}
        {/* <Box>
          <Box {...Size.L} marginBottom="24px">
            {t('user-settings.form.payment.title')}
          </Box>
          <Box marginBottom="16px">{t('user-settings.form.payment.header')}</Box>
          <Box>
            {userCreditCards.map(paymentDetails => (
              <PaymentInfo paymentDetails={paymentDetails} submitDeleteCallback={fetchNewUserCreditCards} />
            ))}
          </Box>
          <Box display="flex" marginTop="24px">
            <ThemeBtn invert={true} onClick={() => setModalOpen(true)}>
              <FontAwesomeIcon icon={faPlus} color={THEME.BLUE} size="xs" style={{ marginRight: '12px' }} />
              {t('user-settings.form.payment.add-button')}
            </ThemeBtn>
          </Box>
        </Box> */}
      </SpacedBox>
      {/* TODO: RELEASED */}
      {/* <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <ModalContainer>
          <AddCardForm onBackToSelectCreditCard={() => setModalOpen(false)} submitCallback={fetchNewUserCreditCards} />
        </ModalContainer>
      </Modal> */}
    </Box>
  )
}

export default React.memo(UserSettings)
