import { Box, Button, Menu, MenuItem } from '@mui/material'
import Flags from 'country-flag-icons/react/3x2'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ReactComponent as LanguageSVG } from '@/assets/images/language-btn.svg'

const LanguageMenu = () => {
  const { i18n } = useTranslation()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const isOpen = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const onChangeLang = (lang: string) => {
    i18n.changeLanguage(lang)
    localStorage.setItem('lang', lang)
    handleClose()
  }

  return (
    <Box>
      <Button
        id="language-button"
        aria-controls={isOpen ? 'language-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isOpen ? 'true' : undefined}
        onClick={handleClick}
      >
        <LanguageSVG />
      </Button>
      <Menu
        id="language-menu"
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'language-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem>
          <Box width="84px" onClick={() => onChangeLang('en')}>
            ENG <Flags.US width="24px" style={{ float: 'right', transform: 'translateY(25%)' }} />
          </Box>
        </MenuItem>
        <MenuItem>
          <Box width="84px" onClick={() => onChangeLang('th')}>
            TH <Flags.TH width="24px" style={{ float: 'right', transform: 'translateY(25%)' }} />
          </Box>
        </MenuItem>
        {/* TODO: RELEASED */}
        {/* <MenuItem>
          <Box width="84px" onClick={() => onChangeLang('cn')}>
            CHN <Flags.CN width="24px" style={{ float: 'right', transform: 'translateY(25%)' }} />
          </Box>
        </MenuItem> */}
      </Menu>
    </Box>
  )
}

export default LanguageMenu
