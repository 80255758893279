import { createGlobalStyle } from 'styled-components'

import { THEME } from './constants/color'
import { LANG } from './constants/language'
import { Size } from './styles'

type Props = {
  lang?: string
}

const GlobalStyle = createGlobalStyle`
  body *:not(path):not(svg) {
    font-family: ${(props: Props) => {
      switch (props.lang) {
        case LANG.EN:
          return `'El Messiri', sans-serif`
        case LANG.CN:
          return 'NotoSerifSC-Regular'
      }
      return 'Boon-Regular'
    }};
    font-size: ${Size.M.fontSize};
    line-height: ${Size.M.lineHeight};
    color: ${THEME.BLUE};
  }

  span.keeptogether {
    white - space: nowrap;
  }

  .MuiPagination-ul {
    margin: auto !important;
    width: fit-content;
  }

  .MuiPagination-ul li button	{
    color: ${THEME.BLUE};
    border: 1px solid ${THEME.LIGHT_BLUE};
  }

  .css-kvsszq-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
    color: ${THEME.WHITE};
    background-color: ${THEME.LIGHT_BLUE} !important;
  }

  .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
    color: ${THEME.LIGHT_BLUE} !important;
    border-bottom: 10px solid ${THEME.LIGHT_BLUE};
  }
`

export default GlobalStyle
