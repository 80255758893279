import CloseIcon from '@mui/icons-material/Close'
import DoneIcon from '@mui/icons-material/Done'
import { Box } from '@mui/material'
import moment from 'moment'
import React from 'react'
import { useAlert } from 'react-alert'
import Countdown from 'react-countdown'
import { useTranslation } from 'react-i18next'

import { claimUtility, getTime } from '@/apis/collections'
// import BackButton from '@/components/common/BackButton'
import ThemeBtn from '@/components/common/ThemeBtn'
import { useCustomQuery } from '@/hooks/useResponsive'
import { Utility } from '@/models/collection'
import { Size } from '@/styles'

import { UtilityPageContainer } from '../styles/UtilityModal'
import '../styles/UtilityModal.css'

interface UtilityModalProps {
  utilities: Utility[]
  skuNo: string
  collectionId: string
  productId: string
  onClose: () => void
  updateUtility: (utility: Utility) => void
}

enum Pages {
  LIST,
  CONFIRM,
  USE,
}

const smallScreenSize = '500px'

const failedMessage = 'Failed to use the privilege.'

const UtilityModal = (props: UtilityModalProps) => {
  const { utilities, collectionId, productId, skuNo, updateUtility } = props

  const [utility, setUtility] = React.useState<Utility>()
  const [page, setPage] = React.useState<Pages>(Pages.LIST)
  const [serverTime, setServerTime] = React.useState<Date>(new Date())
  const [utilityTime, setUtilityTime] = React.useState<Date>(new Date())

  const isSmallScreen = useCustomQuery(smallScreenSize)

  const alert = useAlert()
  const { t } = useTranslation()

  const back = React.useCallback(() => {
    setUtility(undefined)
    setPage(Pages.LIST)
  }, [])

  const isExpired = React.useMemo(() => utilityTime.getTime() - serverTime.getTime() <= 2, [serverTime, utilityTime])

  const confirm = React.useCallback(async () => {
    if (!isExpired && utility) {
      const expiredUtility = await claimUtility(collectionId, productId, utility.utility_id, true)
      const utilityClaimedDate = expiredUtility?.claimed_date
      if (utilityClaimedDate) {
        setUtilityTime(moment(utilityClaimedDate).toDate())
        updateUtility({ ...utility, claimed_date: utilityClaimedDate })
      }
    }
    back()
  }, [back, collectionId, isExpired, productId, updateUtility, utility])

  const claimUtillity = React.useCallback(async () => {
    // call api
    let utilityClaimedDate = utility?.claimed_date
    let serverT
    try {
      if (utility && !utility.claimed_date) {
        const claimedUtility = await claimUtility(collectionId, productId, utility.utility_id)
        if (!claimedUtility.claimed_date) throw new Error('Internal server error')
        utilityClaimedDate = claimedUtility.claimed_date
      }
      serverT = await getTime()
    } catch (err) {
      alert.error(failedMessage)
      return
    }

    setServerTime(moment(serverT).toDate())
    const utilTime = moment(utilityClaimedDate).add({ minutes: 5 }).toDate()
    setUtilityTime(utilTime)
    if (utility && utilityClaimedDate) {
      updateUtility({ ...utility, claimed_date: utilityClaimedDate })
    }
    setPage(Pages.USE)
  }, [alert, collectionId, productId, updateUtility, utility])

  const chooseUtility = React.useCallback(
    (id: number) => {
      const util = utilities.find(u => u.utility_id === id)
      if (util) {
        setUtility(util)
      }
    },
    [utilities],
  )

  React.useEffect(() => {
    if (utility) {
      if (utility.claimed_date) {
        claimUtillity()
          .then(() => {
            setPage(Pages.USE)
          })
          .catch(() => {
            alert.error(failedMessage)
          })
      } else {
        setPage(utility.claimed_date ? Pages.USE : Pages.CONFIRM)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [utility])

  const allUtilities = React.useMemo(
    () => [
      {
        id: 1,
        name: t('collection-product.utility-modal.list.privilege-1.name'),
        redeemedText: t('collection-product.utility-modal.list.privilege-1.redeemed-text'),
      },
      {
        id: 2,
        name: t('collection-product.utility-modal.list.privilege-2.name'),
        redeemedText: t('collection-product.utility-modal.list.privilege-2.redeemed-text'),
      },
      {
        id: 3,
        name: t('collection-product.utility-modal.list.privilege-3.name'),
        redeemedText: t('collection-product.utility-modal.list.privilege-3.redeemed-text'),
      },
    ],
    [t],
  )

  const getNow = React.useCallback(() => {
    const newServerTime = moment(serverTime).add({ seconds: 1 }).toDate()
    setServerTime(newServerTime)
    return newServerTime.getTime()
  }, [serverTime])

  const getCountdown = React.useCallback(
    () => <Countdown date={utilityTime} now={getNow} daysInHours className="countdown" />,
    [getNow, utilityTime],
  )

  const renderPage = React.useCallback(() => {
    const { name, redeemedText } = allUtilities.find(util => util.id === utility?.utility_id) || {}
    switch (page) {
      case Pages.USE:
        return (
          <Box>
            <Box {...(isSmallScreen ? Size.L : Size.XL)}>
              {redeemedText} {skuNo}
            </Box>
            {!isExpired ? (
              <Box fontSize="160px" color="#34B233" marginBottom="24px">
                <DoneIcon fontSize="inherit" />
              </Box>
            ) : (
              <Box fontSize="160px" color="red" marginBottom="24px">
                <CloseIcon fontSize="inherit" />
              </Box>
            )}
            {getCountdown()}
            <Box marginTop="36px" marginBottom="24px">
              {isExpired
                ? t('collection-product.utility-modal.use.expired')
                : t('collection-product.utility-modal.use.in-use')}
            </Box>
            <ThemeBtn invert={isExpired} onClick={confirm}>
              {isExpired
                ? t('collection-product.utility-modal.use.close')
                : t('collection-product.utility-modal.use.confirm')}
            </ThemeBtn>
          </Box>
        )
      case Pages.CONFIRM:
        return (
          <Box>
            <Box>
              <Box {...(isSmallScreen ? Size.L : Size.XL)} marginBottom="8px">
                {t('collection-product.utility-modal.confirm.title', {
                  sku: skuNo,
                  redeemedText: redeemedText?.split(' ')[0],
                })}
              </Box>
              {!redeemedText?.includes(' ') && <Box marginBottom="36px">&#x2022; {name}</Box>}
            </Box>
            <Box
              {...(isSmallScreen ? Size.XS : Size.S)}
              textAlign={t('collection-product.utility-modal.confirm.sub-title-bullet-1') ? 'left' : 'center'}
              marginBottom={t('collection-product.utility-modal.confirm.sub-title-bullet-1') ? 0 : '36px'}
            >
              {t('collection-product.utility-modal.confirm.sub-title')}
            </Box>
            {t('collection-product.utility-modal.confirm.sub-title-bullet-1') && (
              <>
                <Box {...(isSmallScreen ? Size.XS : Size.S)} textAlign="left">
                  &#x2022; {t('collection-product.utility-modal.confirm.sub-title-bullet-1')}
                </Box>
                <Box {...(isSmallScreen ? Size.XS : Size.S)} textAlign="left" marginBottom="36px">
                  &#x2022; {t('collection-product.utility-modal.confirm.sub-title-bullet-2')}
                </Box>
              </>
            )}
            <Box display="flex">
              <ThemeBtn margin="auto" onClick={claimUtillity}>
                {t('collection-product.utility-modal.confirm.accept')}
              </ThemeBtn>
              <ThemeBtn invert margin="auto" onClick={back}>
                {t('collection-product.utility-modal.confirm.decline')}
              </ThemeBtn>
            </Box>
          </Box>
        )
      case Pages.LIST:
      default:
        const utilityIds = utilities.map(util => util.utility_id)
        return (
          <>
            <Box {...(isSmallScreen ? Size.L : Size.XL)} marginBottom="8px">
              {t('collection-product.utility-modal.list.title')}
            </Box>
            <Box {...(isSmallScreen ? Size.XS : Size.S)} textAlign="left">
              {t('collection-product.utility-modal.list.sub-title')}
            </Box>
            {t('collection-product.utility-modal.list.sub-title-bullet-1') && (
              <>
                <Box {...(isSmallScreen ? Size.XS : Size.S)} textAlign="left">
                  &#x2022; {t('collection-product.utility-modal.list.sub-title-bullet-1')}
                </Box>
                <Box {...(isSmallScreen ? Size.XS : Size.S)} textAlign="left" marginBottom="36px">
                  &#x2022; {t('collection-product.utility-modal.list.sub-title-bullet-2')}
                </Box>
              </>
            )}
            {allUtilities.map(util => (
              <ThemeBtn
                {...(isSmallScreen ? Size.S : Size.L)}
                onClick={() => chooseUtility(util.id)}
                disabled={!utilityIds.includes(util.id)}
                marginTop="24px"
                key={util.id}
              >
                {util.name}
              </ThemeBtn>
            ))}
          </>
        )
    }
  }, [
    allUtilities,
    page,
    utility?.utility_id,
    skuNo,
    isExpired,
    getCountdown,
    t,
    confirm,
    claimUtillity,
    back,
    utilities,
    isSmallScreen,
    chooseUtility,
  ])

  const onClose = React.useCallback(() => {
    setPage(Pages.LIST)
    setUtility(undefined)
    props.onClose()
  }, [props])

  return (
    <Box margin="auto" maxWidth="1000px" padding={`${isSmallScreen ? '100px' : '160px'} 5%`}>
      {/* {page === Pages.USE && (
        <Box position="relative">
          <Box position="absolute" left="8px" top="8px" style={{ cursor: 'pointer' }}>
            <BackButton onClick={back} label="" />
          </Box>
        </Box>
      )} */}
      <Box position="relative">
        <Box position="absolute" right="8px" top="8px" style={{ cursor: 'pointer' }} onClick={onClose}>
          <CloseIcon fontSize="large" />
        </Box>
      </Box>
      <UtilityPageContainer padding={isSmallScreen ? '36px 40px' : '48px 64px'}>{renderPage()}</UtilityPageContainer>
    </Box>
  )
}

export default React.memo(UtilityModal)
