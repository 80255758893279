import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, BoxProps } from '@mui/material'

import { onClickUrl } from '@/utils'

interface Props extends BoxProps {
  icon: IconDefinition
  url: string
}

export const SocialBtn = ({ icon, url, ...rest }: Props) => {
  return (
    <Box onClick={() => onClickUrl(url)} fontSize="32px" {...rest}>
      <FontAwesomeIcon icon={icon} style={{ cursor: 'pointer', color: 'white' }} />
    </Box>
  )
}
