import User from '@/models/user'

import axios from '../axios'

interface Response<T> {
  data: T
}

export const getUser: () => Promise<Response<User>> = () => axios.get<any, Response<User>>(`/user/info`)

export const upsertUser: (formData: FormData) => Promise<Response<User>> = formData =>
  axios.post<any, Response<User>, FormData>(`/user/profile`, formData)
