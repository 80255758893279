import { Link, Path } from 'react-router-dom'

import { useResponsive } from '@/hooks/useResponsive'

type Props = {
  src: string
  to: string | Partial<Path>
}

const CarouselLink = ({ src, to }: Props) => {
  return (
    <Link to={to}>
      <img src={src} alt="vaisravana" width={useResponsive({ desktop: '569px', tablet: '400px', mobile: '292px' })} />
    </Link>
  )
}

export default CarouselLink
