import { Box } from '@mui/system'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { THEME } from '@/constants/color'

export const ProfileSettingBtn = styled(Link)`
  border: 1px solid ${THEME.LIGHT_BLUE};
  background-color: white;
  text-align: center;
  cursor: pointer;
  height: fit-content;
  align-self: flex-end;

  :hover {
    background-color: ${THEME.LIGHT_BLUE};
    color: white;
    transition: all 300ms;
  }
`

export const ProfilePicture = styled.img`
  border: 4px solid #1d2d63;
  border-radius: 50%;
  padding: 14px;
  height: 192px;
  width: 192px;
`

export const MyCollectionContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 64px;
  background-color: ${THEME.LIGHT_GREY};

  @media (max-width: 768px) {
    padding: 24px 15px;
  }

  @media (max-width: 390px) {
    padding: 24px 15px;
  }
`
