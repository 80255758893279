import { isMobile } from 'react-device-detect'

import { getChainId } from '@/utils'

import { getAddChainParameters } from './chains'

const connectMetamaskMobile = () => {
  const dappUrl = window.location.href.split('//')[1].split('/')[0]
  const metamaskAppDeepLink = 'https://metamask.app.link/dapp/' + dappUrl
  window.open(metamaskAppDeepLink, '_self')
}

export const connectMetamask = (metamask: any) => {
  if (!isMetamaskInstalled() && isMobile) {
    connectMetamaskMobile()
  } else {
    metamask.activate(getAddChainParameters(getChainId()))
  }
}

export const isMetamaskInstalled = () => {
  if (typeof window === 'undefined') {
    return false
  }

  if (window.ethereum?.isMetaMask) {
    return true
  }

  if (window.ethereum?.providers?.some(p => p.isMetaMask)) {
    return true
  }

  return false
}
