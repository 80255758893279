import { Alert as MuiAlert } from '@mui/material'
import * as React from 'react'
import { AlertTemplateProps } from 'react-alert'

const Alert = ({ message, options, close }: AlertTemplateProps) => {
  return (
    <MuiAlert severity={options.type} sx={{ width: '100%', marginTop: '10px' }}>
      {message}
    </MuiAlert>
  )
}

export default React.memo(Alert)
