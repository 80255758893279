import { faFacebook, faInstagramSquare, faLine, faTiktok, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { Box } from '@mui/system'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import LogoWhite from '@/assets/images/common/logo-white.png'
import { THEME } from '@/constants/color'
import { SOCIAL_URL } from '@/constants/url'
import { useCustomQuery, useTabletQuery } from '@/hooks/useResponsive'

import { SocialBtn } from './SocialBtn'
import { StyledLink } from './styled'

const Footer = () => {
  const isMobile = useCustomQuery('640px')
  const isTablet = useTabletQuery()

  const navigate = useNavigate()

  return (
    <Box bgcolor={THEME.LIGHT_BLUE} padding={`40px ${isTablet ? 24 : 60}px`}>
      <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} marginBottom="48px" maxWidth="1250px">
        <Box display="inline-block" marginBottom={isMobile ? '48px' : '0px'} marginRight="48px">
          <img src={LogoWhite} alt="logo footer" style={{ display: 'block', marginBottom: '16px', height: '60px' }} />
          <Box color="white">© 2022</Box>
        </Box>
        <Box
          display="flex"
          flexDirection={isMobile ? 'column' : 'row'}
          marginLeft={isMobile ? 0 : 'auto'}
          marginRight={isMobile ? 0 : 'auto'}
          width={isMobile ? 'auto' : '600px'}
        >
          <Box marginBottom={isMobile ? '32px' : '0px'} marginRight={isMobile ? 0 : 'auto'}>
            <Box
              color="white"
              fontWeight="bold"
              marginBottom={isMobile ? '18px' : '24px'}
              onClick={() => navigate('/project')}
              style={{ cursor: 'pointer' }}
            >
              About Project
            </Box>
            <Box marginBottom={isMobile ? '10px' : '16px'}>
              <StyledLink to="/project?q=story" style={{ marginBottom: isMobile ? '10px' : '16px' }}>
                Project Story
              </StyledLink>
            </Box>
            <Box marginBottom={isMobile ? '10px' : '16px'}>
              <StyledLink to="/project?q=painpoint" style={{ marginBottom: isMobile ? '10px' : '16px' }}>
                Pain Point
              </StyledLink>
            </Box>
            <Box marginBottom={isMobile ? '10px' : '16px'}>
              <StyledLink to="/project?q=aboutus" style={{ marginBottom: isMobile ? '10px' : '16px' }}>
                About Us
              </StyledLink>
            </Box>
          </Box>
          <Box marginBottom={isMobile ? '32px' : '0px'} marginRight={isMobile ? 0 : 'auto'}>
            <Box
              color="white"
              fontWeight="bold"
              marginBottom={isMobile ? '18px' : '24px'}
              onClick={() => navigate('/collection/1/product')}
              style={{ cursor: 'pointer' }}
            >
              About Product
            </Box>
            <Box marginBottom={isMobile ? '10px' : '16px'}>
              <StyledLink to="/collection/1/product?q=story" style={{ marginBottom: isMobile ? '10px' : '16px' }}>
                Product Story
              </StyledLink>
            </Box>
            <Box marginBottom={isMobile ? '10px' : '16px'}>
              <StyledLink to="/collection/1/product?q=projectdate" style={{ marginBottom: isMobile ? '10px' : '16px' }}>
                Project Date
              </StyledLink>
            </Box>
            <Box marginBottom={isMobile ? '10px' : '16px'}>
              <StyledLink to="/collection/1/product?q=trait" style={{ marginBottom: isMobile ? '10px' : '16px' }}>
                Trait
              </StyledLink>
            </Box>
          </Box>
          {/* TODO: RELEASED */}
          {/* <Box>
            <Box color="white" fontWeight="bold" marginBottom={isMobile ? '18px' : '24px'}>
              Resource
            </Box>
            <Box {...Size.XS} color="white" marginBottom={isMobile ? '10px' : '16px'}>
              FAQ
            </Box>
            <Box {...Size.XS} color="white">
              Blog
            </Box>
          </Box> */}
        </Box>
      </Box>
      <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} alignItems="center">
        {/* TODO: RELEASED */}
        {/* <Box display="flex">
          <Box
            style={{
              color: 'white',
              marginRight: isMobile ? '40px' : '32px',
              marginBottom: isMobile ? '32px' : 0,
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
          >
            Privacy
          </Box>
          <Box style={{ color: 'white', textDecoration: 'underline', cursor: 'pointer' }}>Terms & Condition</Box>
        </Box> */}
        <Box display="flex" marginLeft={isMobile ? 0 : 'auto'}>
          <SocialBtn icon={faTwitter} url={SOCIAL_URL.TWITTER} marginRight="40px" />
          <SocialBtn icon={faInstagramSquare} url={SOCIAL_URL.INSTAGRAM} marginRight="40px" />
          <SocialBtn icon={faLine} url={SOCIAL_URL.LINE} marginRight="40px" />
          <SocialBtn icon={faFacebook} url={SOCIAL_URL.FACEBOOK} marginRight="40px" />
          <SocialBtn icon={faTiktok} url={SOCIAL_URL.TIKTOK} />
          {/* <SocialBtn icon={faTelegram} url={SOCIAL_URL.TELEGRAM} marginRight="40px" /> */}
          {/* <SocialBtn icon={faDiscord} url={SOCIAL_URL.DISCORD} marginRight="40px" /> */}
        </Box>
      </Box>
    </Box>
  )
}

export default React.memo(Footer)
