import { useMediaQuery } from 'react-responsive'

export const useMobileQuery = () => useMediaQuery({ query: '(max-width: 390px)' })
export const useTabletQuery = () => useMediaQuery({ query: '(max-width: 768px)' })
export const useSmallScreenQuery = () => useMediaQuery({ query: '(max-width: 1439px)' })
export const useCustomQuery = (maxWidth: string) => useMediaQuery({ query: `(max-width: ${maxWidth})` })

type Props = { tablet?: any; mobile?: any; smallScreen?: any; desktop: any; custom?: { maxWidth: string; value: any } }

export const useResponsive = ({ tablet, mobile, smallScreen, desktop, custom }: Props) => {
  const isTablet = useTabletQuery()
  const isMobile = useMobileQuery()
  const isSmallScreen = useSmallScreenQuery()
  const isCustom = useCustomQuery(custom?.maxWidth ?? '0')

  if (isMobile && mobile) {
    return mobile
  } else if (isTablet && tablet) {
    return tablet
  } else if (isCustom && custom?.value) {
    return custom.value
  } else if (isSmallScreen && smallScreen) {
    return smallScreen
  }

  return desktop
}
