import { Box } from '@mui/material'
import { t } from 'i18next'
import * as React from 'react'

import { useResponsive } from '@/hooks/useResponsive'
import { Size } from '@/styles'

import ProjectDateHorizontal from './Horizontal'
import ProjectDateVertical from './Vertical'

const ProjectDate = () => (
  <Box maxWidth="1440px" minHeight={useResponsive({ desktop: '839px', tablet: undefined })} margin="auto">
    <Box
      {...useResponsive({ desktop: Size.XXL, tablet: Size.L })}
      padding={useResponsive({ desktop: '80px 0 144px', tablet: '24px 0 32px' })}
      fontWeight="bold"
      textAlign="center"
    >
      {t('home.section4.title')}
    </Box>
    {useResponsive({
      smallScreen: <ProjectDateVertical type="PROJECT_DATE" />,
      desktop: <ProjectDateHorizontal type="PROJECT_DATE" />,
    })}
  </Box>
)

export default React.memo(ProjectDate)
