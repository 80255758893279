import { Box, BoxProps } from '@mui/system'
import React, { ReactNode } from 'react'

import { THEME } from '@/constants/color'

type Props = BoxProps & {
  children: ReactNode
  bgcolor?: string
  direction?: 'left' | 'right' | 'up' | 'down'
  borderColor?: string
}

const ProjectDateBox = ({
  children,
  bgcolor = '#C5D0EC',
  direction,
  borderColor = THEME.LIGHT_BLUE,
  ...rest
}: Props) => {
  const projectDateBox = React.useMemo(() => {
    switch (direction) {
      case 'left':
        return (
          <Box display="flex" {...rest}>
            <Box
              marginTop="auto"
              marginBottom="auto"
              borderRadius="3px"
              borderTop="24px solid transparent"
              borderBottom="24px solid transparent"
              borderRight={`32px solid ${bgcolor}`}
            />
            <Box maxWidth="560px">
              <Box bgcolor={bgcolor} borderRadius="3px" padding="24px">
                {children}
              </Box>
            </Box>
          </Box>
        )
      case 'right':
        return (
          <Box display="flex" {...rest}>
            <Box maxWidth="560px" marginLeft="auto">
              <Box bgcolor={bgcolor} borderRadius="3px" padding="24px" textAlign="right">
                {children}
              </Box>
            </Box>
            <Box
              marginTop="auto"
              marginBottom="auto"
              borderRadius="3px"
              borderTop="24px solid transparent"
              borderBottom="24px solid transparent"
              borderLeft={`32px solid ${bgcolor}`}
            />
          </Box>
        )
      case 'up':
        return (
          <Box width="350px" height="200px" marginTop="32px">
            <Box
              width="0"
              height="0"
              margin="auto"
              borderRadius="3px"
              top="5px"
              left="3px"
              position="relative"
              borderLeft="32px solid transparent"
              borderRight="32px solid transparent"
              borderBottom={`32px solid ${bgcolor}`}
            ></Box>
            <Box borderBottom={`solid ${borderColor} 12px`} bgcolor={bgcolor} borderRadius="3px" padding="24px">
              {children}
            </Box>
          </Box>
        )
      case 'down':
        return (
          <Box width="350px" height="200px">
            <Box borderTop={`solid ${borderColor} 12px`} bgcolor={bgcolor} borderRadius="3px" padding="24px">
              {children}
            </Box>
            <Box
              width="0"
              height="0"
              borderRadius="3px"
              bottom="5px"
              left="4px"
              margin="auto"
              position="relative"
              borderLeft="32px solid transparent"
              borderRight="32px solid transparent"
              borderTop={`32px solid ${bgcolor}`}
            ></Box>
          </Box>
        )
      default:
        return (
          <Box width="100%" borderTop={`12px solid ${borderColor}`} {...rest}>
            <Box bgcolor={bgcolor} borderRadius="3px" padding="24px">
              {children}
            </Box>
          </Box>
        )
    }
  }, [bgcolor, borderColor, children, direction, rest])

  return projectDateBox
}

export default React.memo(ProjectDateBox)
