import { MenuItem, Pagination, Select, Tabs, TextField } from '@mui/material'
import { Box } from '@mui/system'
import moment from 'moment'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { getCollectionProductsById, getCollectionTransactions } from '@/apis/collections'
import HeaderBg from '@/assets/images/collections/collection-header-bg.png'
import TraitBG from '@/assets/images/collections/trait-bg.png'
import TraitBPG from '@/assets/images/collections/trait-bpg.png'
import EthIcon from '@/assets/images/eth.png'
import ProductCard from '@/components/ProductCard'
import BackButton from '@/components/common/BackButton'
import { NLink } from '@/components/common/NLink'
import Table from '@/components/common/Table'
import ThemeBtn from '@/components/common/ThemeBtn'
import { WContainer } from '@/components/common/WContainer'
import { THEME } from '@/constants/color'
import useDebounce from '@/hooks/useDebounce'
import { useResponsive } from '@/hooks/useResponsive'
import { CenterContainer, Size } from '@/styles'
import { openLineShop, truncateString } from '@/utils'

import { Product, Transaction } from '../../models/collection'
import TabPanel from './components/TabPanel'
import { CollectionGalleryTab } from './styles/CollectionGalleryPage'

const traits = [
  {
    name: '#1VSBG',
    count: 250,
    rarity: '50',
    price: '฿1,499',
    image: TraitBG,
  },
  {
    name: '#1VSBPG',
    count: 250,
    rarity: '50',
    price: '฿1,499',
    image: TraitBPG,
  },
]

const CollectionGalleryPage = () => {
  const { t } = useTranslation()
  const { id } = useParams()
  const navigate = useNavigate()
  const [tab, setTab] = React.useState<number>(0)
  const [limit, setLimit] = React.useState<string | number>(20)
  const [page, setPage] = React.useState<number>(1)
  const [totalPage, setTotalPage] = React.useState<number>(10)
  const [searchInput, setSearchInput] = React.useState<string>('') // realtime search text
  const [keyword, setKeyword] = React.useState<string>('') // must equal to searchInput after debound value
  const [products, setProducts] = React.useState<Product[] | []>([])
  const [transactions, setTransactions] = React.useState<Transaction[]>([])

  const onTabChange = React.useCallback((event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue)
  }, [])

  const gridTemplateColumns = useResponsive({ desktop: 'repeat(4, 1fr)', tablet: 'repeat(2, 1fr)' })

  useEffect(() => {
    if (!id) return

    const fetchCollectionDetails = async () => {
      const res = await getCollectionTransactions(id)
      setTransactions(res.result)
    }
    fetchCollectionDetails()
  }, [id])

  useEffect(() => {
    if (!id) return

    if (id !== '1') {
      navigate('/collection/1')
    }

    const fetchCollectionProducts = async () => {
      const res = await getCollectionProductsById(id!!, page, limit, keyword)

      if (res && res.result) {
        setProducts(res.result)
        setTotalPage(res.totalPage)
      }
    }
    fetchCollectionProducts()
  }, [id, navigate, page, limit, keyword])

  useDebounce(() => {
    setKeyword(searchInput)
  }, 300)

  return (
    <>
      <Box color={THEME.BLUE} padding="5vh 24px 0" style={{ background: `url(${HeaderBg})`, backgroundSize: 'cover' }}>
        <WContainer>
          <BackButton />
          <Box display="flex" marginBottom="68px" justifyContent="space-between">
            <Box {...Size.XXL}>{t('collections.header.title')}</Box>
            <Box display="flex" flexDirection={useResponsive({ desktop: 'row', tablet: 'column' })}>
              <NLink
                to={`/collection/${id}/product`}
                style={{
                  marginLeft: 'auto',
                  marginRight: '24px',
                }}
              >
                <ThemeBtn invert={true}>{t('collections.header.button.about-collection')}</ThemeBtn>
              </NLink>
              <ThemeBtn onClick={openLineShop}>{t('collections.header.button.buy-now')}</ThemeBtn>
            </Box>
          </Box>
          <Tabs value={tab} onChange={onTabChange}>
            <CollectionGalleryTab label={t('collections.tab.gallery.name')} />
            <CollectionGalleryTab label={t('collections.tab.transaction-history.name')} />
            <CollectionGalleryTab label={t('collections.tab.trait.name')} />
          </Tabs>
        </WContainer>
      </Box>
      <Box bgcolor={tab === 0 ? THEME.WHITE : THEME.LIGHT_GREY} padding="24px">
        <WContainer>
          <TabPanel value={tab} index={0}>
            <Box padding="0">
              <Box display="flex" padding="40px 0">
                <TextField
                  label={t('collections.tab.gallery.search-input.placeholder')}
                  style={{ width: '100%', backgroundColor: THEME.WHITE }}
                  size="small"
                  value={searchInput}
                  onChange={e => setSearchInput(e.target.value)}
                />
                {/* <FormControl fullWidth style={{ margin: 'auto', width: '25%' }}>
                <InputLabel id="sortBy-select-label">sort by</InputLabel>
                <Select labelId="sortBy-select-label" id="sortBy-select" label="sortBy" size="small">
                  <MenuItem value={1}>Popularity</MenuItem>
                  <MenuItem value={2}>Price</MenuItem>
                </Select>
              </FormControl> */}
              </Box>
              {/* <Box marginBottom="64px">
              <input type="radio" name="isOnSell" value="all" />
              <label style={{ marginLeft: '16px' }}>all</label>
              <input type="radio" name="isOnSell" value="onSell" style={{ marginLeft: '33px' }} />
              <label style={{ marginLeft: '16px' }}>on sell</label>
            </Box> */}
              {products && products.length > 0 ? (
                <Box display="grid" gridTemplateColumns={gridTemplateColumns} gap="24px">
                  {products.map(product => (
                    <Link
                      to={`/collection/${id}/product/${product.product_id}`}
                      style={{
                        textDecoration: 'none',
                        color: THEME.BLUE,
                      }}
                      key={product.product_id}
                    >
                      <ProductCard image={product.metadata ? product.metadata.image : ''} title={product.product_id} />
                    </Link>
                  ))}
                </Box>
              ) : (
                <CenterContainer>No products</CenterContainer>
              )}
              <Box margin="48px 0">
                <Box
                  margin={useResponsive({ desktop: '0 12px 0', tablet: '0 12px 24px' })}
                  position={useResponsive({ desktop: 'absolute', tablet: 'inherit' })}
                >
                  {t('collections.tab.gallery.pagination.limit.text1')}
                  <Select
                    value={limit}
                    label="limit"
                    onChange={e => setLimit(e.target.value)}
                    style={{ margin: '0 12px' }}
                  >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                  </Select>
                  {t('collections.tab.gallery.pagination.limit.text2')}
                </Box>

                <Pagination
                  count={totalPage}
                  size="large"
                  variant="outlined"
                  shape="rounded"
                  onChange={(event: React.ChangeEvent<unknown>, pageNumber: number) => setPage(pageNumber)}
                />
              </Box>
            </Box>
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <Box padding="88px 0" minHeight="20vh">
              <Table
                titles={[
                  t('collections.tab.transaction-history.table.column1.title'),
                  t('collections.tab.transaction-history.table.column2.title'),
                  t('collections.tab.transaction-history.table.column3.title'),
                  t('collections.tab.transaction-history.table.column4.title'),
                ]}
                rows={transactions.map(txn => [
                  <Box display="flex" alignItems="center" justifyContent="center">
                    <img
                      src={txn.productId.startsWith('BG') ? TraitBG : TraitBPG}
                      width="28px"
                      height="28px"
                      alt={txn.productId}
                      style={{
                        marginRight: '20px',
                        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                        borderRadius: '2px',
                      }}
                    />
                    <Box>{txn.productId}</Box>
                  </Box>,
                  <Box display="flex" alignItems="center" justifyContent="center">
                    <img src={EthIcon} alt="eth" style={{ height: '24px', width: '24px', marginRight: '8px' }} />
                    <Box>฿1,499</Box>
                  </Box>,
                  txn.buyerAddress ? truncateString(txn.buyerAddress) : '',
                  moment(new Date(txn.createdAt)).format('DD/MM/YYYY'),
                ])}
              />
            </Box>
          </TabPanel>
          <TabPanel value={tab} index={2}>
            <Box padding="88px 0" minHeight="20vh">
              <Table
                titles={[
                  t('collections.tab.trait.table.column1.title'),
                  t('collections.tab.trait.table.column2.title'),
                  t('collections.tab.trait.table.column3.title'),
                  t('collections.tab.trait.table.column4.title'),
                ]}
                rows={traits.map(trait => [
                  <Box display="flex" alignItems="center" justifyContent="center">
                    <img
                      src={trait.image}
                      width="28px"
                      height="28px"
                      alt={trait.name}
                      style={{
                        marginRight: '20px',
                        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                        borderRadius: '2px',
                      }}
                    />
                    <Box>{trait.name}</Box>
                  </Box>,
                  trait.count,
                  `${trait.rarity} %`,
                  <Box display="flex" alignItems="center" justifyContent="center">
                    <Box>{trait.price}</Box>
                  </Box>,
                ])}
              />
            </Box>
          </TabPanel>
        </WContainer>
      </Box>
    </>
  )
}

export default React.memo(CollectionGalleryPage)
