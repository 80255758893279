import { Box, Checkbox, CheckboxProps } from '@mui/material'
import * as React from 'react'

import { THEME } from '@/constants/color'

interface ThemeCheckboxProps extends CheckboxProps {
  label?: string
}

const ThemeCheckbox = ({ label, ...rest }: ThemeCheckboxProps) => (
  <Box display="flex">
    <Checkbox
      sx={{
        color: THEME.LIGHT_BLUE,
        '&.Mui-checked': {
          color: THEME.LIGHT_BLUE,
        },
      }}
      {...rest}
    />
    {label && <Box margin="auto 40px auto 0">{label}</Box>}
  </Box>
)

export default React.memo(ThemeCheckbox)
