import { AddressZero } from '@ethersproject/constants'
import { Contract } from '@ethersproject/contracts'
import { useMemo } from 'react'

import NFT_ABI from '@/constants/abis/NFT.json'
import NFT_STORE_ABI from '@/constants/abis/NFTStore.json'
import { getContract } from '@/functions/contract'

import { useActiveWeb3React } from './useActiveWeb3React'

const useContract = (address: string | undefined, ABI: any, withSignerIfPossible = true): Contract | null => {
  const { library, account } = useActiveWeb3React()

  return useMemo(() => {
    if (!address || address === AddressZero || !ABI || !library) return null
    try {
      return getContract(address, ABI, library, withSignerIfPossible && account ? account : undefined)
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [address, ABI, library, withSignerIfPossible, account])
}

export const useNFTContract = (withSignerIfPossible = true): Contract | null => {
  return useContract(process.env.REACT_APP_NFT_ADDRESS, NFT_ABI, withSignerIfPossible)
}

export const useNFTStoreContract = (withSignerIfPossible = true): Contract | null => {
  return useContract(process.env.REACT_APP_NFT_STORE_ADDRESS, NFT_STORE_ABI, withSignerIfPossible)
}
