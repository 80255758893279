import { Box } from '@mui/material'

import { metaMask, metaMaskHooks } from '@/connectors/metamask'

import { Connect } from './Connect'

const { useError, useIsActivating, useIsActive } = metaMaskHooks

export const ConnectMetaMask = () => {
  const error = useError()
  const isActivating = useIsActivating()
  const isActive = useIsActive()

  return (
    <Box display="flex" flexDirection="column">
      <Connect connector={metaMask} isActivating={isActivating} error={error} isActive={isActive} />
    </Box>
  )
}
