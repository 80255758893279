import { useResponsive } from '@/hooks/useResponsive'
import { CenterContainer, SectionContainer } from '@/styles'

const CenterBanner = ({ src }: { src: any }) => {
  return (
    <CenterContainer>
      <SectionContainer>
        <img
          src={src}
          alt="cover"
          style={{
            width: '100%',
            height: useResponsive({ desktop: '550px', tablet: '275px' }),
            objectFit: 'cover',
            objectPosition: 'center',
          }}
        />
      </SectionContainer>
    </CenterContainer>
  )
}

export default CenterBanner
