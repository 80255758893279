import { Box, BoxProps } from '@mui/system'
import * as React from 'react'

type IconTitleProps = BoxProps & {
  Icon: React.ReactNode
}

const IconTitle = ({ Icon, ...rest }: IconTitleProps) => (
  <Box bgcolor="#C5D0EC" width="60px" height="60px" borderRadius="4px" {...rest}>
    {Icon}
  </Box>
)

export default React.memo(IconTitle)
