import { Box, Button, Menu, MenuItem } from '@mui/material'
import type { Web3ReactHooks } from '@web3-react/core'
import type { MetaMask } from '@web3-react/metamask'
import { t } from 'i18next'
import { useMemo, useState } from 'react'

import { ReactComponent as WalletSVG } from '@/assets/images/wallet.svg'
import { metaMaskHooks } from '@/connectors/metamask'
import { THEME } from '@/constants/color'
import { connectMetamask } from '@/functions/connectWallet'
import { useCustomQuery } from '@/hooks/useResponsive'
import { truncateString } from '@/utils'

const { useAccounts } = metaMaskHooks

type Props = {
  connector: MetaMask
  isActivating: ReturnType<Web3ReactHooks['useIsActivating']>
  error: ReturnType<Web3ReactHooks['useError']>
  isActive: ReturnType<Web3ReactHooks['useIsActive']>
}

export function Connect({ connector, isActivating, error, isActive }: Props) {
  const connectWallet = () => connectMetamask(connector)
  const accounts = useAccounts()
  const walletId = useMemo(() => accounts?.[0], [accounts])

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const isOpen = Boolean(anchorEl)

  const isSmallScreen = useCustomQuery('1200px')

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const walletSvgStyle = { cursor: 'pointer', padding: '6px 8px' }
  const truncatedWalletId: string = useMemo(() => (walletId ? truncateString(walletId) : ''), [walletId])

  const message = useMemo(
    () => isSmallScreen && <Box margin="14px 0 0 14px">{t('navbar.connect-wallet')}</Box>,
    [isSmallScreen],
  )

  if (error) {
    return (
      <Box display="flex" onClick={connectWallet}>
        <WalletSVG style={walletSvgStyle} />
        {message}
      </Box>
    )
  } else if (isActive && walletId) {
    return (
      <>
        <Button
          id="connect-wallet-button"
          aria-controls={isOpen ? 'connect-wallet-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={isOpen ? 'true' : undefined}
          onClick={handleClick}
          style={{ minWidth: 0 }}
        >
          <WalletSVG fill={THEME.GOLD} style={{ cursor: 'pointer' }} />
          {isSmallScreen && <Box marginLeft="22px">{truncatedWalletId}</Box>}
        </Button>
        {!isSmallScreen && (
          <Menu
            id="connect-wallet-menu"
            anchorEl={anchorEl}
            open={isOpen}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'connect-wallet-button',
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <MenuItem>{truncatedWalletId}</MenuItem>
          </Menu>
        )}
      </>
    )
  } else {
    return (
      <Box display="flex" onClick={connectWallet}>
        <WalletSVG fill={THEME.GREY} style={walletSvgStyle} />
        {message}
      </Box>
    )
  }
}
