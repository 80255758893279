import { Box } from '@mui/material'
import styled, { css } from 'styled-components'

import { THEME } from '@/constants/color'

interface IThemeBtn {
  invert?: boolean
  hover?: boolean
  disabled?: boolean
  type?: string
  fullWidth?: boolean
}

const getBgColor = (invert = false, disabled = false) => {
  if (disabled) {
    return THEME.DISABLED
  } else if (invert) {
    return THEME.WHITE
  }

  return THEME.LIGHT_BLUE
}

const getFontColor = (invert = false, disabled = false) => {
  if (disabled) {
    return THEME.WHITE
  } else if (invert) {
    return THEME.LIGHT_BLUE
  }

  return THEME.WHITE
}

const getBorder = (disabled = false) => {
  if (disabled) {
    return THEME.DISABLED
  }

  return THEME.LIGHT_BLUE
}

const ThemeBtn = styled(Box)<IThemeBtn>`
  border: 1px solid ${props => getBorder(props.disabled)};
  border-radius: 2px;
  background-color: ${props => getBgColor(props.invert, props.disabled)};
  color: ${props => getFontColor(props.invert, props.disabled)};
  text-align: center;
  padding: 12px 48px;
  height: fit-content;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};

  ${({ hover, invert }: IThemeBtn) =>
    hover &&
    css`
      :hover {
        border: 1px solid ${invert ? THEME.WHITE : THEME.LIGHT_BLUE};
        background-color: ${invert ? THEME.LIGHT_BLUE : THEME.WHITE};
        color: ${invert ? THEME.WHITE : THEME.LIGHT_BLUE};
        transition: all 300ms;
      }
    `}

  @media (max-width: 768px) {
    padding: 12px 24px;
  }

  @media (max-width: 390px) {
    padding: 12px 24px;
  }
`

export default ThemeBtn
