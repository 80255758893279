import CollectionBoxImage from '@/assets/images/collection-box.png'

import { CollectionBox } from './types'

export const collectionBoxes: CollectionBox[] = [
  {
    id: 1,
    name: 'NORMAL BOX',
    imageSrc: CollectionBoxImage,
  },
  {
    id: 2,
    name: 'MEDIUM BOX',
    imageSrc: CollectionBoxImage,
  },
  {
    id: 3,
    name: 'PREMIUM BOX',
    imageSrc: CollectionBoxImage,
  },
]

export const fallbackProductId: { [key: string]: string } = {
  BG1: 'BG1501',
  BG2: 'BG1502',
  BG3: 'BG1503',
  BG4: 'BG1504',
  BG5: 'BG1505',
  BG6: 'BG1506',
  BG7: 'BG1507',
  BG8: 'BG1508',
  BG9: 'BG1509',
  BG10: 'BG1510',
  BG11: 'BG1511',
  BG12: 'BG1512',
  BG13: 'BG1513',
  BG14: 'BG1514',
  BG15: 'BG1515',
  BG16: 'BG1516',
  BG17: 'BG1517',
  BG18: 'BG1518',
  BG19: 'BG1519',
  BG20: 'BG1520',
  BG21: 'BG1521',
  BG22: 'BG1522',
  BG23: 'BG1523',
  BG24: 'BG1524',
  BG25: 'BG1525',
  BG26: 'BG1526',
  BG27: 'BG1527',
  BG28: 'BG1528',
  BG29: 'BG1529',
  BG30: 'BG1530',
  BG31: 'BG1531',
  BG32: 'BG1532',
  BG33: 'BG1533',
  BG34: 'BG1534',
  BG35: 'BG1535',
  BG36: 'BG1536',
  BG37: 'BG1537',
  BG38: 'BG1538',
  BG39: 'BG1539',
  BG40: 'BG1540',
  BG41: 'BG1541',
  BG42: 'BG1542',
  BG43: 'BG1543',
  BG44: 'BG1544',
  BG45: 'BG1545',
  BG46: 'BG1546',
  BG47: 'BG1547',
  BG48: 'BG1548',
  BG49: 'BG1549',
  BG50: 'BG1550',
  BPG1: 'BPG1751',
  BPG2: 'BPG1752',
  BPG3: 'BPG1753',
  BPG4: 'BPG1754',
  BPG5: 'BPG1755',
  BPG6: 'BPG1756',
  BPG7: 'BPG1757',
  BPG8: 'BPG1758',
  BPG9: 'BPG1759',
  BPG10: 'BPG1760',
  BPG11: 'BPG1761',
  BPG12: 'BPG1762',
  BPG13: 'BPG1763',
  BPG14: 'BPG1764',
  BPG15: 'BPG1765',
  BPG16: 'BPG1766',
  BPG17: 'BPG1767',
  BPG18: 'BPG1768',
  BPG19: 'BPG1769',
  BPG20: 'BPG1770',
  BPG21: 'BPG1771',
  BPG22: 'BPG1772',
  BPG23: 'BPG1773',
  BPG24: 'BPG1774',
  BPG25: 'BPG1775',
  BPG26: 'BPG1776',
  BPG27: 'BPG1777',
  BPG28: 'BPG1778',
  BPG29: 'BPG1779',
  BPG30: 'BPG1780',
  BPG31: 'BPG1781',
  BPG32: 'BPG1782',
  BPG33: 'BPG1783',
  BPG34: 'BPG1784',
  BPG35: 'BPG1785',
  BPG36: 'BPG1786',
  BPG37: 'BPG1787',
  BPG38: 'BPG1788',
  BPG39: 'BPG1789',
  BPG40: 'BPG1790',
  BPG41: 'BPG1791',
  BPG42: 'BPG1792',
  BPG43: 'BPG1793',
  BPG44: 'BPG1794',
  BPG45: 'BPG1795',
  BPG46: 'BPG1796',
  BPG47: 'BPG1797',
  BPG48: 'BPG1798',
  BPG49: 'BPG1799',
  BPG50: 'BPG1800',
}
