import { faClipboard } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'
import React from 'react'
import { useAlert } from 'react-alert'
import CopyToClipboard from 'react-copy-to-clipboard'
import styled from 'styled-components'

import { truncateString } from '@/utils'

export const CopyBtn = styled(Box)`
  cursor: pointer;
  background-color: #d6b757;
  padding: 12px;
  width: 248px;
  border-radius: 6px;
  text-align: center;
`

interface WalletIdBoxProps {
  walletId: string
}

const WalletIdBox = (props: WalletIdBoxProps) => {
  const { walletId } = props

  const alert = useAlert()

  const onCopy = React.useCallback(() => {
    alert.success('Copied to clipboard', {
      offset: '100px',
    })
  }, [alert])

  return (
    <>
      <CopyBtn>
        <CopyToClipboard text={walletId} onCopy={onCopy}>
          <Box>
            {truncateString(walletId)} <FontAwesomeIcon icon={faClipboard} />
          </Box>
        </CopyToClipboard>
      </CopyBtn>
    </>
  )
}

export default React.memo(WalletIdBox)
